import React, { useEffect } from "react";
import "./App.css";
import { useStoreActions, useStoreState } from "easy-peasy";
import Layout from "./Components/Layout/Layout";
import ListingView from "./Components/Layout/ListingView";
import MapLayoutView from "./Components/Layout/MapLayoutView";
import PricingViewLayout from "./Components/Layout/PricingViewLayout";
import ErrorBoundary from "./Components/Utils/ErrorBoundary";

function App() {
  const authenticate = useStoreActions(state => state.auth.authenticateThunk);
  const user = useStoreState(state => state.auth.user);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  if (!user) {
    return <div>Authenticating...</div>;
  }

  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <ErrorBoundary>
          <ListingView />
        </ErrorBoundary>
          <MapLayoutView />
        <ErrorBoundary>
          <PricingViewLayout/>
        </ErrorBoundary>
      </div>
    </Layout>
  );
}

export default App;
