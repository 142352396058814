import React from "react";
import MapSelection from "../Maps/MapSelection";
import { SalesNotesGraphSection } from "./SalesNotesGraphSection";

export default function MapLayoutView() {



  return(
    <div style={{ marginLeft: 20, width: 750 }}>
      <MapSelection />
      <SalesNotesGraphSection />
    </div>
  )

}