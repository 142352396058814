import React, { useRef, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import useClickOutside from "../../../utils/click_outside";
import { GROUP_VIEW } from "../../../Store/LayoutStore";

const doesAllListingsAreUngrouped = (
  listingsChecked,
  listingsByGroup,
  pricingsArray
) => {
  return (
    listingsChecked.length > 0 &&
    listingsChecked.every(
      listing =>
        listingsByGroup[0] &&
        !pricingsArray.some(pricing => pricing.listing_id === listing) &&
        listingsByGroup[0].some(
          groupListing => groupListing.listing_id === listing
        )
    )
  );
};

export default React.memo(function BulkActionsButton({ isAnyListingChecked }) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [subDropdownStatus, setSubDropdownStatus] = useState(false);
  const [subDropdownFloorStatus, setSubDropdownFloorStatus] = useState(false);
  const [subDropdownDeleteStatus, setSubDropdownDeleteStatus] = useState(false);
  const [floorType, setFloorType] = useState("set");
  const [floor, setFloor] = useState("0");
  const [increment, setIncrement] = useState(-1);

  const isFloorValid = !isNaN(parseFloat(floor)) && parseFloat(floor) > 0;

  const listingsChecked = useStoreState(
    state => state.listings.checkedListings
  );
  const listingsByGroup = useStoreState(
    state => state.listings.getListingsByGroup
  );

  const bulkChangeFloor = useStoreActions(
    actions => actions.pricing.bulkChangeFloorThunk
  );

  const groups = useStoreState(state => state.groups.itemsArray);

  const pricingsArray = useStoreState(state => state.pricing.itemsArray);

  const eventId = useStoreState(state => state.events.eventId);
  const changeListingView = useStoreActions(
    actions => actions.layout.changeListingView
  );

  const isElligibleForAddtoNewGroup = doesAllListingsAreUngrouped(
    listingsChecked,
    listingsByGroup,
    pricingsArray
  );

  const isElligibleForAddtoExistingGroup =
    isElligibleForAddtoNewGroup && groups.length > 0;

  const isElligibleForSettingFloor = true;

  const createGroup = useStoreActions(actions => actions.groups.createGroup);
  const selectGroup = useStoreActions(actions => actions.groups.selectGroup);
  const addListingsInGroup = useStoreActions(
    actions => actions.groups.addListingsInGroup
  );

  const deleteCriteria = useStoreActions(
    actions => actions.pricing.deleteCriteriaThunk
  );
  const deleteCriteriaFromGroup = useStoreActions(
    actions => actions.groups.deleteListingFromGroupThunk
  );

  const onClickOutside = () => {
    if (dropdownStatus || subDropdownStatus) {
      setDropdownStatus(false);
      setSubDropdownStatus(false);
    }
  };

  const uncheckAllListings = useStoreActions(actions => actions.listings.uncheckAllListings);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, onClickOutside);

  return (
    <div ref={wrapperRef}>
      <button
        type="button"
        className="btn btn-brand dropdown-toggle"
        data-toggle="dropdown"
        style={{ cursor: !isAnyListingChecked ? "not-allowed" : "inherit" }}
        disabled={!isAnyListingChecked}
        onClick={evt => {
          evt.stopPropagation();
          setDropdownStatus(!dropdownStatus);
        }}
      >
        Bulk Actions <i className="fa fa-caret-down"></i>
      </button>
      <div
        style={{ left: 350 }}
        className={`dropdown-menu${dropdownStatus ? " show" : ""}`}
      >
        <button
          style={{ border: "none" }}
          onClick={e => {
            e.stopPropagation();
            changeListingView(GROUP_VIEW);
            createGroup(eventId);
            addListingsInGroup(listingsChecked);
          }}
          className={`dropdown-item${
            !isElligibleForAddtoNewGroup ? " disabled" : ""
          }`}
          href="#"
        >
          Create New Group
        </button>
        <button
          style={{ border: "none" }}
          onClick={e => {
            e.stopPropagation();
            setDropdownStatus(false);
            setSubDropdownStatus(true);
          }}
          className={`dropdown-item${
            !isElligibleForAddtoExistingGroup ? " disabled" : ""
          }`}
          href="#"
        >
          Add to an Existing Group
        </button>
        <button
          onClick={e => {
            e.stopPropagation();
            setDropdownStatus(false);
            setSubDropdownFloorStatus(true);
          }}
          style={{ border: "none" }}
          className={`dropdown-item ${isElligibleForSettingFloor}`}
          href="#"
        >
          Set Floor Price
        </button>
        <button
          style={{ border: "none" }}
          className="dropdown-item"
          onClick={e => {
            e.stopPropagation();
            setDropdownStatus(false);
            setSubDropdownDeleteStatus(true);
          }}
        >
          Delete
        </button>
      </div>

      <div
        style={{ left: -60 }}
        className={`dropdown-menu${subDropdownFloorStatus ? " show" : ""}`}
      >
        <button
          onClick={() => setFloorType("set")}
          className={"dropdown-item no-highlight"}
          style={{ border: "none" }}
        >
          <label className="kt-radio kt-radio--bold kt-radio--brand">
            <input
              onChange={() => {
                setFloorType("set");
              }}
              checked={floorType === "set" ? "checked" : ""}
              type="radio"
            />{" "}
            Set Floor
            <span></span>
          </label>
        </button>
        <button
          onClick={() => setFloorType("raise")}
          className={"dropdown-item no-highlight"}
          style={{ border: "none" }}
        >
          <label className="kt-radio kt-radio--bold kt-radio--brand">
            <input
              onChange={() => {
                setFloorType("raise");
              }}
              checked={floorType === "raise" ? "checked" : ""}
              type="radio"
            />{" "}
            Raise / Lower Floor
            <span></span>
          </label>
        </button>

        <div className="dropdown-divider"></div>

        <div className={"dropdown-item raise-floor-btn"}>
          <div className="input-group input-group-sm">
            {floorType === "raise" && (
              <div className="input-group-prepend input-group-sm">
                <button
                  onClick={e => {
                    setIncrement(increment * -1);
                  }}
                  className={`btn btn-${increment < 0 ? "google" : "success"}`}
                  type="button"
                  style={{ height: 32, width: 34 }}
                >
                  {increment < 0 ? "-" : "+"}
                </button>
              </div>
            )}

            <input
              className={"dropdown-item raise-floor-btn"}
              onFocus={evt => evt.target.select()}
              value={floor}
              onChange={e => {
                setFloor(e.target.value);
              }}
              style={{
                textAlign: "right",
                width: floorType === "raise" ? 125 : "100%",
                paddingLeft: 0,
                paddingRight: 10,
                backgroundColor: "#f7f8fa",
                outline: 0
              }}
              type={"text"}
            />
          </div>
        </div>

        <div className="btn-group btn-group-sm dropdown-item">
          <button
            onClick={e => {
              e.stopPropagation();
              setSubDropdownFloorStatus(false);
              setDropdownStatus(true);
              setFloor("0");
            }}
            type="button"
            className="btn btn-outline-danger"
          >
            Cancel
          </button>
          <button
            onClick={e => {
              e.stopPropagation();
              bulkChangeFloor({
                type: floorType,
                listings: listingsChecked,
                floor: parseFloat(floor) * (floorType === "set" ? 1 : increment)
              });
              setSubDropdownFloorStatus(false);
              setDropdownStatus(false);
              setFloor("0");
              uncheckAllListings();
            }}
            disabled={!isFloorValid}
            type="button"
            className="btn btn-outline-success"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
        </div>
      </div>

      <div
        style={{ left: -60 }}
        className={`dropdown-menu${subDropdownStatus ? " show" : ""}`}
      >
        {groups.map(group => {
          return (
            <button
              style={{ border: "none" }}
              onClick={e => {
                e.stopPropagation();
                changeListingView(GROUP_VIEW);
                selectGroup(group);
                addListingsInGroup(listingsChecked);
                uncheckAllListings();
              }}
              key={group.id}
              className="dropdown-item"
              href="#"
            >
              {group.name}
            </button>
          );
        })}
        <div className="dropdown-divider"></div>
        <button
          style={{ border: "none" }}
          onClick={e => {
            e.stopPropagation();
            setSubDropdownStatus(false);
            setDropdownStatus(true);
          }}
          className="dropdown-item"
          href="#"
        >
          Cancel
        </button>
      </div>

      <div
        style={{ left: -60 }}
        className={`dropdown-menu${subDropdownDeleteStatus ? " show" : ""}`}
      >
        <button
          style={{ border: "none" }}
          onClick={e => {
            e.stopPropagation();

            const listingsNotInGroup = listingsByGroup[0]
              .filter(l => {
                return listingsChecked.includes(l.listing_id);
              })
              .map(v => v.listing_id);

            const listingsInGroup = listingsChecked.filter(
              l => !listingsNotInGroup.includes(l)
            );
            deleteCriteria(listingsNotInGroup);
            deleteCriteriaFromGroup(listingsInGroup);
            setSubDropdownDeleteStatus(false);
            setDropdownStatus(false);
            uncheckAllListings();
          }}
          className="dropdown-item"
          href="#"
        >
          Confirm
        </button>

        <div className="dropdown-divider"></div>
        <button
          style={{ border: "none" }}
          onClick={e => {
            e.stopPropagation();
            setSubDropdownDeleteStatus(false);
            setDropdownStatus(true);
          }}
          className="dropdown-item"
          href="#"
        >
          Cancel
        </button>
      </div>
    </div>
  );
});
