import { PricingInterface, PricingStoreModel } from "../types";
import { action, actionOn, computed, thunk, thunkOn } from "easy-peasy";

const { calculateCriteriaPrice } = require("@jfortin/kobe-criteria");

const DEFAULT_PRICING: PricingInterface = {
  id: null,
  sections: [],
  split: [],
  delivery_type: ["1"],
  attributes: ["1", "2", "3", "5"],
  ceiling: 0,
  floor: 0,
  increment_amount: -1.0,
  increment_type: 1,
  listing_id: null,
  price: 0,
  row_max: null,
  row_min: null
};

export const PricingStore: PricingStoreModel = {
  items: {},
  isLoading: false,
  selectedPricing: null,
  previouslySavedCriteria: null,
  lastClickedMapSection: null,

  isCriteriaSaved: computed(
    [state => state.previouslySavedCriteria],
    savedCriteria => {
      return !!savedCriteria;
    }
  ),
  clearSavedCriteria: action(state => {
    state.previouslySavedCriteria = null;
  }),
  selectedListingSections: computed(
    [state => state.selectedPricing],
    selectedPricing => {
      if (selectedPricing) {
        return selectedPricing.sections;
      }
      return [];
    }
  ),

  itemsArray: computed(state => {
    return Object.keys(state.items).map((key: any) => {
      return state.items[key];
    });
  }),
  setToLoading: action(state => {
    state.isLoading = true;
  }),
  pricingByListingId: computed(
    [
      state => state.itemsArray,
      (_, store) => store.groups.listingsArray,
      (_, store) => store.groups.items,
      state => state.items
    ],
    (itemsArray, groupListings, groups, pricingDictionnary) => (
      listingId: any
    ) => {
      const found = itemsArray.find(
        (pricing: { listing_id: any }) =>
          pricing.listing_id &&
          pricing.listing_id.toString() === listingId.toString()
      );

      if (found) {
        return found;
      }

      const foundGroupPricing = groupListings.find(
        (groupListing: { listing_id: { toString: () => any } }) =>
          groupListing.listing_id.toString() === listingId.toString()
      );

      if (!foundGroupPricing) {
        return null;
      }

      if (groups[foundGroupPricing.group_id]) {
        return pricingDictionnary[
          groups[foundGroupPricing.group_id].pricing_id
        ];
      }
      return null;
    }
  ),

  pricingById: computed(state => (id: any) =>
    state.itemsArray.find(
      (pricing: { id: any }) =>
        pricing.id && pricing.id.toString() === id.toString()
    )
  ),

  unselectAll: action(state => {
    state.items = {};
    state.selectedPricing = null;
    state.previouslySavedCriteria = null;
    state.lastClickedMapSection = null;
  }),

  unselect: action(state => {
    state.selectedPricing = null;
  }),

  onDeleteListingInGroup: actionOn(
    (state, store) => store.groups.deleteListingFromGroup,
    (state, target) => {
      state.selectedPricing = null;
    }
  ),

  poOnSkybox: thunk(async (actions, payload) => {
    try {
      await fetch(`/api/listings/create`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json"
        }
      });
      return true;
    } catch(err) {
      console.log(err);
      return false
    }

  }),

  onChangeToSkyboxView: actionOn(
    (state, store) => store.layout.changeToSkyboxView,
    (state, target) => {
      state.selectedPricing = null;
      state.lastClickedMapSection = null;
    }
  ),

  onDeleteGroup: actionOn(
    (state, store) => store.groups.deleteGroup,
    (state, target) => {
      const newPricings: any = {};
      Object.keys(state.items).forEach((key: any) => {
        if (key.toString() !== target.payload.pricingId.toString()) {
          newPricings[key] = state.items[key];
        }
      });
      state.items = newPricings;
    }
  ),

  onUnselectGroup: actionOn(
    (state, store) => store.groups.unselectGroup,
    (state, target) => {
      state.selectedPricing = null;
    }
  ),

  onUpdateGroupSuccess: thunkOn(
    (_, store) => store.groups.updateGroupThunk.successType,
    (actions, target) => {
      // @ts-ignore
      actions.loadPricingsThunk({
        eventId: target.payload.group.event_id,
        reload: true
      });
    }
  ),

  onSelectGroup: thunkOn(
    (actions, store) => store.groups.selectGroup,
    async (actions, target) => {
      actions.selectPricingById(target.payload.pricing_id);
    }
  ),

  onCreateGroup: thunkOn(
    (actions, store) => store.groups.createGroup,
    async (actions, target) => {
      actions.selectDefaultPricing();
    }
  ),

  onSelectListing: thunkOn(
    (actions, store) => store.listings.selectListing,
    async (actions, target) => {
      actions.selectPricingByListing(target.payload);
    }
  ),
  criteriaPrice: computed(
    [
      state => state.selectedPricing,
      (_, store) => {
        return store.listings.selectedListing;
      },
      (_, store) => {
        return store.stubhub_listings.filteredStubhubListings;
      }
    ],
    (selectedPricing, selectedListing, filteredStubhubListings) => {
      if (!selectedPricing) {
        return;
      }
      const criteriaPrice = calculateCriteriaPrice(
        selectedPricing,
        filteredStubhubListings
      );

      if (!criteriaPrice) {
        return selectedListing ? selectedListing.price : 0;
      }
      return criteriaPrice;
    }
  ),
  selectDefaultPricing: action(state => {
    if (state.previouslySavedCriteria) {
      state.selectedPricing = state.previouslySavedCriteria;
      // @ts-ignore
      delete state.selectedPricing.id;
      // @ts-ignore
      delete state.selectedPricing.listing_id;
    } else {
      state.selectedPricing = { ...DEFAULT_PRICING };
    }
  }),
  selectPricingByListing: action((state, payload) => {
    let pricing = state.pricingByListingId(payload.id);

    if (pricing) {
      state.previouslySavedCriteria = { ...pricing };
      state.selectedPricing = pricing;
      return;
    }

    // TODO extract somehow and get settings from DB / by users/ by events... whatever.

    if (state.previouslySavedCriteria) {
      pricing = state.previouslySavedCriteria;
    } else {
      pricing = { ...DEFAULT_PRICING };
    }

    let split: any = [];
    const quantity = payload.quantity;

    switch (quantity) {
      case 1:
        split = [];
        break;
      case 2:
      case 3:
      case 4:
      case 6:
        split = [quantity.toString()];
        break;
      case 5:
      case 7:
      case 8:
        split = [(quantity - 2).toString(), quantity.toString()];
        break;
      default:
        split = ["8"];
        break;
    }
    pricing.floor = (payload.cost * 1.2).toFixed(2);
    pricing.split = split;
    pricing.id = null;
    pricing.listing_id = null;

    state.selectedPricing = pricing;
  }),

  bulkChangeFloor: action((state, payload) => {
    payload.forEach(({ pricingId, floor }: any) => {
      if (state.items[pricingId]) {
        state.items[pricingId].floor = floor;
      }
    });
  }),

  bulkChangeFloorThunk: thunk(async (actions, payload, { getStoreState }) => {
    const pricings = payload.listings
      .map((listing: any) =>
        getStoreState().pricing.pricingByListingId(listing)
      )
      .filter((v: any) => !!v)
      .map((v: any) => v.id);
    if (pricings.length <= 0) {
      return;
    }

    const pricingsWithoutDuplicates = Array.from(new Set(pricings));

    const newPricings = pricingsWithoutDuplicates.map((pricing: any) => {
      return {
        pricingId: pricing,
        floor:
          payload.type === "set"
            ? parseFloat(payload.floor).toFixed(2)
            : Math.max(
                1,
                parseFloat(payload.floor) +
                  getStoreState().pricing.items[pricing].floor
              ).toFixed(2)
      };
    });

    actions.bulkChangeFloor(newPricings);

    await fetch(`/api/pricing/bulkeditfloor`, {
      method: "POST",
      body: JSON.stringify(newPricings),
      headers: {
        "Content-Type": "application/json"
      }
    });
  }),

  resetCriteria: action(state => {
    const pricing = { ...DEFAULT_PRICING };
    delete pricing.id;
    delete pricing.listing_id;

    state.selectedPricing = {
      ...state.selectedPricing,
      ...pricing
    };
  }),

  selectPricingById: action((state, payload) => {
    const pricing = state.pricingById(payload);

    if (pricing) {
      state.previouslySavedCriteria = { ...pricing };
    }

    state.selectedPricing = pricing
      ? pricing
      : {
          ...DEFAULT_PRICING
        };
  }),
  loadPricingsThunk: thunk(async (actions, payload) => {
    if (!payload.reload) {
      actions.setToLoading();
    }
    const response = await fetch(`/api/pricing/event/${payload.eventId}`);
    const jsonData: any = await response.json();
    actions.loadPricings(jsonData);
  }),
  loadPricings: action((state, payload) => {
    state.items = {};
    payload.forEach(listing => {
      const listingId = listing.id;
      state.items[listingId] = listing;
    });
    state.isLoading = false;
  }),
  onLoadEvent: thunkOn(
    (actions, store) => store.events.loadEventDataThunk.startType,
    async (actions, target) => {
      actions.unselectAll();
      // @ts-ignore
      actions.loadPricingsThunk({ eventId: target.payload.id });
    }
  ),

  toggleAttributes: action((state, payload) => {
    const selectedPricing = state.selectedPricing;

    if (!selectedPricing) {
      return;
    }

    if (selectedPricing.attributes.includes(payload)) {
      selectedPricing.attributes = selectedPricing.attributes.filter(
        (sp: any) => sp !== payload
      );
    } else {
      selectedPricing.attributes = [...selectedPricing.attributes, payload];
    }
  }),
  toggleDeliveryType: action((state, payload) => {
    const selectedPricing = state.selectedPricing;
    if (!selectedPricing) {
      return;
    }

    if (payload === 0) {
      selectedPricing.delivery_type = [];
      return;
    }

    if (selectedPricing.delivery_type.includes(payload)) {
      selectedPricing.delivery_type = selectedPricing.delivery_type.filter(
        (sp: any) => sp !== payload
      );
    } else {
      selectedPricing.delivery_type = [
        ...selectedPricing.delivery_type,
        payload
      ];
    }
  }),
  toggleSplits: action((state, payload) => {
    const selectedPricing = state.selectedPricing;
    if (!selectedPricing) {
      return;
    }

    if (payload === "All") {
      selectedPricing.split = [];
      return;
    }

    if (selectedPricing.split.includes(payload)) {
      selectedPricing.split = selectedPricing.split.filter(
        (sp: any) => sp !== payload
      );
    } else {
      selectedPricing.split = [...selectedPricing.split, payload];
    }
  }),
  deleteCriteriaThunk: thunk(async (actions, payload) => {
    actions.deleteCriteria(payload);
    await fetch(`/api/pricing/delete`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });
  }),
  deleteCriteria: action((state, payload: any) => {
    if (
      state.selectedPricing &&
      payload.includes(state.selectedPricing.listing_id)
    ) {
      state.selectedPricing = null;
    }
    const newPricings = { ...state.items };

    Object.keys(newPricings).forEach((pricingId: any) => {
      if (
        newPricings[pricingId] &&
        payload.includes(newPricings[pricingId].listing_id)
      ) {
        delete newPricings[pricingId];
      }
    });

    state.items = newPricings;
  }),
  updatePricingThunk: thunk(async (actions, payload) => {
    try {
      const response = await fetch(`/api/pricing`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const pricing = await response.json();

      payload.id = pricing.id;
      actions.updatePricing(payload);
    } catch (err) {
      console.log(err);
      console.log("Error!");
    }
  }),
  savePreviousCriteria: action((state, payload) => {
    state.previouslySavedCriteria = payload;
  }),
  updatePricing: action((state, payload) => {
    state.items[payload.id] = payload;
    state.selectedPricing = payload;
    state.previouslySavedCriteria = payload;
  }),
  changePricingValue: action((state, { key, value }) => {
    if (!state.selectedPricing) {
      return;
    }
    // @ts-ignore
    state.selectedPricing[key] = value;
  }),
  resetMapSection: action((state, payload) => {
    const selectedPricing = state.selectedPricing;
    if (!selectedPricing) {
      return;
    }
    selectedPricing.sections = [];
  }),
  toggleMapZone: action((state, payload) => {
    const selectedPricing = state.selectedPricing;
    if (!selectedPricing) {
      return;
    }

    let sections: any;
    if (payload.every(section => selectedPricing.sections.includes(section))) {
      sections = selectedPricing.sections.filter(
        (s: string) => !payload.includes(s)
      );
    } else {
      const notAdded = payload.filter(
        section => !selectedPricing.sections.includes(section)
      );
      sections = [...selectedPricing.sections, ...notAdded];
    }

    state.selectedPricing = {
      ...selectedPricing,
      sections
    };
  }),
  toggleMapSection: action((state, payload) => {
    const selectedPricing = state.selectedPricing;
    state.lastClickedMapSection = payload;
    if (!selectedPricing) {
      return;
    }

    let sections: any;
    if (selectedPricing.sections.includes(payload)) {
      sections = selectedPricing.sections.filter((s: string) => s !== payload);
    } else {
      sections = [...selectedPricing.sections, payload];
    }

    state.selectedPricing = {
      ...selectedPricing,
      sections
    };
  })
};
