import React from "react";
import moment from "moment";
import { useStoreState } from "easy-peasy";
import { NOTE_FULL_HEIGHT, NOTE_FULL_VIEW, NOTE_SMALL_HEIGHT } from "../../Store/LayoutStore";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function ActiveInventory() {
  const eventId = useStoreState(state => state.events.eventId);
  const activeInventory = useStoreState(state => state.serena.inventorySnapshots);
  const noteView = useStoreState(state => state.layout.noteView);

  if (!eventId || activeInventory.length === 0) {
    return null;
  }

  const getSnapshotDateLabels = (data) => {
    const sortedData = data.sort((a, b) => moment(a.snapshotDate).diff(moment(b.snapshotDate)));
    return sortedData.map(item => {
        const date = moment.utc(item.snapshotDate);
        return {
                date: date.format('DD MMM'),
                inventory: item.inventory
            };
        }
    )
    }

    const graphData = getSnapshotDateLabels(activeInventory);

    const yAxisPadding = 5;

    const maxDataValue = Math.max(...graphData.map(item => item.inventory));

    const yAxisMax = maxDataValue + yAxisPadding;

  return (
    <div
      className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
      style={{ height: `calc(100vh - 695px + ${noteView !== NOTE_FULL_VIEW ? NOTE_FULL_HEIGHT - NOTE_SMALL_HEIGHT : 0}px)`, overflow: "auto" }}
    >
        {graphData && (
            <LineChart
                width={435}
                height={260}
                data={graphData}
                margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={[0, yAxisMax]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="inventory" stroke="#8884d8" dot={false} />
            </LineChart>
        )}
      
    </div>
  );
}

export default ActiveInventory;
