import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

export const MatchEvent = () => {
  const selectedEvent = useStoreState(state => state.events.selectedEvent);

  const [eventId, setEventId] = useState("");
  const matchEvent = useStoreActions(
    actions => actions.events.matchEventThunk
  );

  const onSubmit = () => {
    selectedEvent.seatgeek_id = eventId;
    matchEvent(selectedEvent);
  };

  return (
    <>
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Match Event</h3>
        </div>
      </div>

      <div style={{ paddingBottom: 0 }} className="kt-portlet__body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: 450,
            justifyContent: "space-between"
          }}
        >
          <div className={"row"} style={{ marginBottom: 50 }}>
            <div className={"col-lg-10"}>
              <input
                className="form-control"
                name="event-id"
                placeholder="Seatgeek Id"
                type="text"
                value={eventId}
                onChange={e => setEventId(e.target.value)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
            </div>
            <div className={"col-lg-2"}>
              <button
                onClick={onSubmit}
                disabled={eventId.length < 6}
                className={"btn btn-success"}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
