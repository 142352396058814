import React, { useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import EventSearch from "../Events/EventSearch";

function Sidebar({ isOpen }) {
  const toggleSidebar = useStoreActions(action => action.layout.toggleSidebar);
  const loadEvents = useStoreActions(action => action.events.loadEventsThunk);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const emptySideBar = (
    <div
      style={{
        zIndex:10,
        width: 40,
        minWidth: 40,
        backgroundColor: "#e4f0fe",
        height: "100vh",
        minHeight: "100vh",
        textAlign: "center",
        cursor: "pointer",
        display: isOpen ? "none" : "block"
      }}
      onClick={toggleSidebar}
    >
      <i
        style={{ color: "#3d94fb", fontSize: 48 }}
        className="fa fa-angle-double-right"
      ></i>
    </div>
  );

  const sideBar = (
    <div
      style={{
        zIndex:10,
        position: "fixed",
        display: isOpen ? "block" : "none"
      }}
      className="kt-aside kt-aside--minimize  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
      id="kt_aside"
    >
      <div
        style={{ marginBottom: 40 }}
        className="kt-aside__brand kt-grid__item "
        id="kt_aside_brand"
      >
        <div
          style={{ margin: "0 auto", marginTop: 40, textAlign: "center" }}
          className="kt-aside__brand-logo"
        >
          <a href={process.env.REACT_APP_DOMAIN}>
            <img
              style={{ textAlign: "center" }}
              alt="Logo"
              src="./logo.png"
            />
          </a>
        </div>
      </div>

      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu  kt-aside-menu--dropdown "
        >
          <EventSearch />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {emptySideBar}
      {sideBar}
    </>
  );
}

export default Sidebar;
