import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Sales from "../Sales/Sales";
import ActiveInventory from "../Serena/ActiveInventory";
import SaleSnapshots from "../Serena/SaleSnapshots";
import NotesList from "../Events/NotesList";
import GetIns from "../Events/GetInsList";
import Tab from "../Utils/Tab";
import {
  EVENT_NOTES_TABS,
  NOTE_FULL_VIEW,
  SALES_TABS,
  INVO_TABS,
  OWN_SALES_TABS,
  GET_INS_TABS
} from "../../Store/LayoutStore";

export function SalesNotesGraphSection() {
  const activeTab = useStoreState(state => state.layout.activeTab);
  const changeTab = useStoreActions(actions => actions.layout.changeTab);
  let component;

  const noteView = useStoreState(state => state.layout.noteView);
  const toggleNoteView = useStoreActions(state => state.layout.toggleNoteView);

  const currentEventLoading = useStoreState(
    state => state.events.currentEventLoading
  );
  const selectedEvent = useStoreState(state => state.events.selectedEvent);

  const isEventSelected = !!currentEventLoading || !!selectedEvent;

  switch (activeTab) {
    case EVENT_NOTES_TABS:
      component = <NotesList style={{ height: "35vh", overflow: "auto" }} />;
      break;
    case SALES_TABS:
      component = <Sales style={{ height: "35vh", overflow: "auto" }} />;
      break;
    case INVO_TABS:
      component = <ActiveInventory style={{ height: "35vh", overflow: "auto" }} />;
      break;
    case OWN_SALES_TABS:
      component = <SaleSnapshots style={{ height: "35vh", overflow: "auto" }} />;
      break;
    case GET_INS_TABS:
        component = <GetIns style={{ height: "35vh", overflow: "auto" }} />;
        break;
    default:
      throw new Error(`Tabs ${activeTab} does not exist`);
  }

  const isLoadingEvent = useStoreState(state => state.events.loading);

  return (
    <div style={{ marginTop: 10 }} className="kt-portlet kt-portlet--tabs">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-toolbar">
          <ul
            className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-2x"
            role="tablist"
            style={{
              display: !isLoadingEvent && !selectedEvent ? "none" : "flex"
            }}
          >
            <Tab
              name={"Notes"}
              value={EVENT_NOTES_TABS}
              icon={"sticky-note"}
              activeTab={activeTab}
              onSelect={changeTab}
              isLinkActivated={isEventSelected}
              isOver={true}
            />

            <Tab
              name={"Inventory"}
              value={INVO_TABS}
              icon={"pie-chart"}
              activeTab={activeTab}
              onSelect={changeTab}
              isLinkActivated={isEventSelected}
              isOver={true}
            />

            
            <Tab
              name={"Own Sales"}
              value={OWN_SALES_TABS}
              icon={"pie-chart"}
              activeTab={activeTab}
              onSelect={changeTab}
              isLinkActivated={isEventSelected}
              isOver={true}
            />

            <Tab
              name={"Sales"}
              value={SALES_TABS}
              icon={"dollar"}
              activeTab={activeTab}
              onSelect={changeTab}
              isLinkActivated={isEventSelected}
              isOver={true}
            />

            <Tab
                name={"Get INs"}
                value={GET_INS_TABS}
                icon={"dollar"}
                activeTab={activeTab}
                onSelect={changeTab}
                isLinkActivated={isEventSelected}
                isOver={true}
            />
          </ul>
        </div>
        <i
          style={{ fontSize: 40, cursor: "pointer", marginTop: 7 }}
          onClick={toggleNoteView}
          className={`fa fa-angle-double-${
            noteView === NOTE_FULL_VIEW ? "up" : "down"
          }`}
        />
      </div>

      {component}
    </div>
  );
}
