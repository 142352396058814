import React, { useState } from "react";

function FilterColumn({name, onSubmit}) {

  const [isActivated, setActivation] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  return (
    <>
        <button onClick={() => setActivation(true)}>
          <i
            style={{ fontSize: 12, color: "#646c9a", position: "relative", top:"-1px" }}
            className="fa fa-filter"
          />
        </button>

      {isActivated && <div className="filterListing">
        <input
          autoFocus={true}
          onBlur={() => {
            setActivation(false);
            setFilterValue("");
          }}
          onKeyPress={evt => {
            evt.stopPropagation();
            if (evt.key === "Enter" && filterValue) {
              setActivation(false);
              setFilterValue("");
              onSubmit(filterValue);
            }
          }}
          value={filterValue}
          onChange={e => setFilterValue(e.target.value)}
          className="form-control"
        />
      </div>}
    </>
  );
}

export default FilterColumn;
