import React from "react";


export default function StockTypeIcon({stockType}) {
  switch (stockType) {
    case "ELECTRONIC":
      return <i className="stock-type-icons stocktype-electronic" />;
    case "MOBILE_TRANSFER":
      return <i className="stock-type-icons stocktype-mobile-transfer" />;
    case "MOBILE_SCREENCAP":
      return (
        <i
          style={{
            fontSize: "20px",
            position: "relative",
            top: "4px"
          }}
          className="fa fa-qrcode"
        />
      );
    case "HARD":
      return (
        <i
          style={{
            fontSize: "20px",
            position: "relative",
            top: "4px"
          }}
          className="fa fa-h-square"
        />
      );
    default:
      return <div>`Stock type ${stockType} does not exist`</div>
  }


}