import React from "react";
import { Tooltip } from 'react-tooltip'


export const NotesIcon = ({ internal_note, public_note, id }) => {

    if (!public_note && !internal_note) {
        return <></>
    }

  return (
    <>
      <Tooltip id={id} multiline />
      {(!!public_note || !!internal_note) && <span style={{ display: "inline", fontSize:18 }} data-tooltip-id={id} data-tooltip-place={"top"} data-tooltip-html={`Internal: ${internal_note ? internal_note : "None"} <br/> Public: ${public_note ? public_note : "None"}`}>
        <i style={{cursor: "pointer"}} className="fa fa-sticky-note-o" />{" "}
      </span>}
    </>
  );
};
