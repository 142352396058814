import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div style={{ marginTop: 10, height: 556 }} className="kt-portlet">
        <div
          style={{
            margin: "auto",
            fontSize: 28,
            fontWeight: 400
          }}
          className="kt-portlet__body"
        >
          <span>
            <i className="fa fa-ban" /> Something went wrong.
          </span>
        </div>
      </div>
    }

    return this.props.children;
  }
}