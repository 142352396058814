import React from "react";
import { useStoreState } from "easy-peasy";
import SalesRow from "./SalesRow";
import { NOTE_FULL_HEIGHT, NOTE_FULL_VIEW, NOTE_SMALL_HEIGHT } from "../../Store/LayoutStore";

function Sales() {
  const eventId = useStoreState(state => state.events.eventId);
  const sales = useStoreState(state => state.sales.rows);
  const noteView = useStoreState(state => state.layout.noteView);

  const currentCriteria = useStoreState(state => state.pricing.selectedPricing);
  
  const filteredSales = sales.filter(sale => {
    if (!currentCriteria) {
      return true;
    }
    if (currentCriteria.sections.length > 0) {
      if (
        !currentCriteria.sections.some(section => {
          if (!sale.s) {
            return false;
          }
          return section.toString() === sale.s.toString();
        })
      ) {
        return false;
      }
    }
    return true;
  });

  if (!eventId) {
    return null;
  }

  return (
    <div
      className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
      style={{ height: `calc(100vh - 695px + ${noteView !== NOTE_FULL_VIEW ? NOTE_FULL_HEIGHT - NOTE_SMALL_HEIGHT : 0}px)`, overflow: "auto" }}
    >
      {sales && (
        <table
          className="kt-datatable__table"
          width="100%"
          style={{ display: "table" }}
        >
          <thead
            style={{ display: "table-header-group"}}
            className="kt-datatable__head"
          >
            <tr className="kt-datatable__row">
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Date</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Quantity</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Section</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Row</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Price</span>
              </th>
            </tr>
          </thead>
          <tbody
            style={{ display: "table-row-group" }}
            className="kt-datatable__body"
          >
            {filteredSales.map(sale => (
              <SalesRow key={`${eventId}-${sale.putc}`} sale={sale} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Sales;
