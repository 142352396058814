import ListingRow from "./ListingRow";
import React from "react";

export default function ListingsUngrouped({ listings, groups, onTabPress }) {
  return (
    <>
      {listings.map((listing) => {
        return (
          <ListingRow
            onTabPress={onTabPress}
            groups={groups}
            key={listing.id}
            id={listing.id}
          />
        );
      })}
    </>
  );
}
