import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm } from "react-hook-form";
import "./Pricing.css";
import SplitButton from "./Buttons/SplitButton";
import IncrementPlusMinusButton from "./Buttons/IncrementPlusMinusButton";
import IncrementTypeButton from "./Buttons/IncrementTypeButton";
import AttributeButton from "./Buttons/AttributeButton";
import DeliveryTypeButton from "./Buttons/DeliveryTypeButton";
import { GROUP_VIEW } from "../../Store/LayoutStore";

export default function Pricing() {
  const { handleSubmit } = useForm();
  const listingView = useStoreState((state) => state.layout.listingView);

  const selectedGroup = useStoreState((state) => state.groups.selectedGroup);
  const selectedListing = useStoreState(
    (state) => state.listings.selectedListing
  );
  const selectedPricing = useStoreState(
    (state) => state.pricing.selectedPricing
  );

  const filteredStubhubListings = useStoreState(
    (state) => state.stubhub_listings.filteredStubhubListings
  );
  const comparable = filteredStubhubListings.find((listing) => {
    return !listing.sellerOwnInd;
  });

  const resetCriteriaFct = useStoreActions(
    (state) => state.pricing.resetCriteria
  );

  const unselectGroup = useStoreActions((state) => state.groups.unselectGroup);

  const unselectPricing = useStoreActions((state) => state.pricing.unselect);

  const criteriaPrice = useStoreState((state) => state.pricing.criteriaPrice);

  const toggleSplitsFct = useStoreActions(
    (action) => action.pricing.toggleSplits
  );
  const toggleDeliveryTypeFct = useStoreActions(
    (action) => action.pricing.toggleDeliveryType
  );
  const toggleAttributesFct = useStoreActions(
    (action) => action.pricing.toggleAttributes
  );
  const changePricingValueFct = useStoreActions(
    (action) => action.pricing.changePricingValue
  );
  const changeGroupValueFct = useStoreActions(
    (actions) => actions.groups.changeGroupValue
  );
  const updatePricing = useStoreActions(
    (action) => action.pricing.updatePricingThunk
  );

  const updateGroup = useStoreActions(
    (actions) => actions.groups.updateGroupThunk
  );

  const savePreviousCriteria = useStoreActions(
    (actions) => actions.pricing.savePreviousCriteria
  );

  const eventId = useStoreState((state) => state.events.eventId);

  const [pristine, changePristine] = useState(true);
  const [isButtonClickable, setButtonClickable] = useState(true);

  useEffect(() => {
    changePristine(true);
  }, [selectedListing]);

  const changeGroupValue = (arg) => {
    changePristine(false);
    changeGroupValueFct(arg);
  };
  const changePricingValue = (arg) => {
    changePristine(false);
    changePricingValueFct(arg);
  };
  const toggleSplits = (arg) => {
    changePristine(false);
    toggleSplitsFct(arg);
  };
  const toggleDeliveryType = (arg) => {
    changePristine(false);
    toggleDeliveryTypeFct(arg);
  };
  const toggleAttributes = (arg) => {
    changePristine(false);
    toggleAttributesFct(arg);
  };
  const resetCriteria = (arg) => {
    changePristine(false);
    resetCriteriaFct(arg);
  };

  if (!selectedPricing) {
    return (
      <div style={{ marginTop: 10 }} className="kt-portlet kt-portlet--tab">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              {listingView === GROUP_VIEW ? "Group " : " "}Pricing
            </h3>
          </div>
        </div>

        <div style={{ width: "100%", height: "300px", display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <span style={{ fontSize: 28, fontWeight: 400 }}>
              <i className="fa fa-ban" /> No{" "}
              {listingView === GROUP_VIEW ? "groups " : "listings"} selected
            </span>
          </div>
        </div>
      </div>
    );
  }

  const spaceoutIncrementType = !!selectedGroup && selectedGroup.spaceout_type;
  const spaceoutAmount = !!selectedGroup && selectedGroup.spaceout_amount;
  const broadcastItems = !!selectedGroup && selectedGroup.broadcast_items;
  const name = selectedGroup ? selectedGroup.name : null;

  const {
    floor,
    row_min,
    row_max,
    ceiling,
    increment_type: incrementType,
    increment_amount: incrementAmount,
  } = selectedPricing;

  const invalidFloor = selectedPricing.floor <= 0;
  const invalidCeiling = selectedPricing.ceiling < 0;
  const hasNoSection = selectedPricing.sections.length <= 0;
  const hasEmptyName = !!selectedGroup && !name;
  const invalidSpaceout = !!selectedGroup && spaceoutAmount < 0;

  const invalidButton =
    !isButtonClickable ||
    invalidFloor ||
    hasNoSection ||
    hasEmptyName ||
    invalidSpaceout ||
    invalidCeiling;

  const onSubmit = async () => {
    setButtonClickable(false);
    changePristine(true);
    const pricingData = {
      ...selectedPricing,
      event_id: eventId,
      cmp: !!comparable ? criteriaPrice : 0,
    };
    if (selectedGroup) {
      await updateGroup({
        pricing: {
          ...pricingData,
          listing_id: null,
        },
        listings: selectedGroup.listings,
        group: {
          id: selectedGroup.id,
          event_id: eventId,
          name,
          pricing_id: pricingData.id,
          spaceout_amount: spaceoutAmount,
          spaceout_type: spaceoutIncrementType,
          broadcast_items: broadcastItems,
        },
      });
      savePreviousCriteria(pricingData);
      if (listingView === GROUP_VIEW) {
        unselectGroup();
        unselectPricing();
      }
      setButtonClickable(true);
      return;
    }

    await updatePricing({
      ...pricingData,
      listing_id: pricingData.id ? pricingData.listing_id : selectedListing.id,
      is_broadcast: selectedListing.is_broadcast,
    });
    setButtonClickable(true);
  };

  return (
    <div
      style={{ marginTop: 10, border: pristine ? "none" : "solid 5px #1dc9b7" }}
      className="kt-portlet kt-portlet--tab"
    >
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {listingView === GROUP_VIEW ? "Group " : " "}Pricing
          </h3>
        </div>

        <div className="kt-portlet__head-toolbar">
          <button
            className="btn btn-brand"
            data-toggle="tab"
            href="#"
            role="tab"
            onClick={resetCriteria}
          >
            Reset
          </button>
        </div>
      </div>
      <div style={{ paddingBottom: 0 }} className="kt-portlet__body">
        <div style={{ marginBottom: 0 }} className="kt-section">
          <form className="kt-form" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ flexDirection: "column" }}>
              {selectedGroup && (
                <div className="form-group input-group-sm row">
                  <label className={"col-lg-3 col-form-label"}>Name</label>
                  <div className="input-group col-lg-9 input-group-sm">
                    <input
                      className={`form-control form-control-sm ${
                        hasEmptyName ? "is-invalid" : ""
                      }`}
                      name={"name"}
                      placeholder={"Group Name"}
                      type={"text"}
                      min={0}
                      step={"any"}
                      value={name || ""}
                      onChange={(evt) => {
                        changeGroupValue({
                          key: "name",
                          value: evt.target.value,
                        });
                      }}
                    />
                    {hasEmptyName && (
                      <div className="invalid-feedback">
                        You must enter a name
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Row</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"50%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={"From"}
                    name={"row_min"}
                    value={row_min || ""}
                    onChange={(evt) => {
                      changePricingValue({
                        key: "row_min",
                        value: evt.target.value,
                      });
                    }}
                  />

                  <input
                    width={"50%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={"To"}
                    name={"row_max"}
                    value={row_max || ""}
                    onChange={(evt) => {
                      changePricingValue({
                        key: "row_max",
                        value: evt.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Splits</label>

                <div className="btn-group btn-group col-lg-9" role="group">
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"All"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"1"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"2"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"3"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"4"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"5"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"6"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"7"}
                  />
                  <SplitButton
                    splits={selectedPricing.split}
                    toggleSplits={toggleSplits}
                    name={"8"}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Increment</label>
                <div className="input-group col-lg-9 input-group-sm">
                  <div className="input-group-prepend input-group-sm">
                    <IncrementPlusMinusButton
                      value={incrementAmount}
                      changeValue={(v) => {
                        changePricingValue({
                          key: "increment_amount",
                          value:
                            v > 0
                              ? Math.abs(incrementAmount)
                              : Math.abs(incrementAmount) * -1,
                        });
                      }}
                    />
                  </div>
                  <input
                    name={"increment"}
                    value={Math.abs(incrementAmount)}
                    onChange={(evt) => {
                      changePricingValue({
                        key: "increment_amount",
                        value:
                          incrementAmount > 0
                            ? Math.abs(evt.target.value)
                            : Math.abs(evt.target.value) * -1,
                      });
                    }}
                    step={"any"}
                    min={0}
                    type="number"
                    className="form-control form-control-sm"
                  />
                  <div className="input-group-append input-group-sm">
                    <IncrementTypeButton
                      setIncrement={changePricingValue}
                      increment={incrementType}
                      pricingKey={"increment_type"}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Floor</label>
                <div className="input-group input-group-sm col-lg-4">
                  <input
                    className={`form-control form-control-sm ${
                      invalidFloor ? "is-invalid" : ""
                    }`}
                    name={"floor"}
                    type={"number"}
                    min={0}
                    step={"any"}
                    value={selectedPricing.floor}
                    onChange={(evt) => {
                      changePricingValue({
                        key: "floor",
                        value: evt.target.value,
                      });
                    }}
                  />
                  {invalidFloor && (
                    <div className="invalid-feedback">
                      Floor must be higher than 0
                    </div>
                  )}
                </div>
                <label className={"col-lg-2 col-form-label"}>Ceiling</label>
                <div className="input-group input-group-sm col-lg-3">
                  <input
                    className={`form-control form-control-sm ${
                      invalidCeiling ? "is-invalid" : ""
                    }`}
                    name={"ceiling"}
                    type={"number"}
                    min={0}
                    step={"any"}
                    value={ceiling}
                    onChange={(evt) => {
                      changePricingValue({
                        key: "ceiling",
                        value: evt.target.value,
                      });
                    }}
                  />
                  {invalidCeiling && (
                    <div className="invalid-feedback">
                      Ceiling must be higher than 0
                    </div>
                  )}
                </div>
              </div>

              {selectedGroup && (
                <>
                  <div className="form-group input-group-sm row">
                    <label className={"col-lg-3 col-form-label"}>
                      Items to broadcast
                    </label>
                    <div className="input-group col-lg-9 input-group-sm">
                      <input
                        className="form-control form-control-sm"
                        name={"broadcast_items"}
                        type={"number"}
                        min={0}
                        step={1}
                        value={broadcastItems || 0}
                        onChange={(evt) => {
                          changeGroupValue({
                            key: "broadcast_items",
                            value: evt.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group input-group-sm row">
                    <label className={"col-lg-3 col-form-label"}>
                      Spaceout
                    </label>
                    <div className="input-group col-lg-9 input-group-sm">
                      <input
                        className="form-control form-control-sm"
                        name={"spaceout"}
                        type={"number"}
                        min={0}
                        step={"any"}
                        value={spaceoutAmount}
                        onChange={(evt) => {
                          changeGroupValue({
                            key: "spaceout_amount",
                            value: evt.target.value,
                          });
                        }}
                      />
                      <div className="input-group-append input-group-sm">
                        <IncrementTypeButton
                          setIncrement={changeGroupValue}
                          increment={spaceoutIncrementType}
                          pricingKey={"spaceout_type"}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="form-group input-group-sm row">
                <div
                  className="btn-group btn-group col-lg-4 input-group-sm"
                  role="group"
                >
                  <DeliveryTypeButton
                    delivery_types={selectedPricing.delivery_type}
                    toggleDeliveryType={toggleDeliveryType}
                    name={<i className="fa fa-flash" />}
                    value={"1"}
                  />
                  <DeliveryTypeButton
                    delivery_types={selectedPricing.delivery_type}
                    toggleDeliveryType={toggleDeliveryType}
                    name={<i className="fa fa-h-square" />}
                    value={"3"}
                  />
                  <DeliveryTypeButton
                    delivery_types={selectedPricing.delivery_type}
                    toggleDeliveryType={toggleDeliveryType}
                    name={<i className="fa fa-truck" />}
                    value={"4"}
                  />
                </div>
                <div
                  className="btn-group btn-group col-lg-8 input-group-sm"
                  role="group"
                >
                  <AttributeButton
                    attributes={selectedPricing.attributes}
                    toggleAttributes={toggleAttributes}
                    name={
                      <i
                        title="Exclude Obstructed View"
                        className="fa fa-low-vision"
                      />
                    }
                    value={"1"}
                  />
                  <AttributeButton
                    attributes={selectedPricing.attributes}
                    toggleAttributes={toggleAttributes}
                    name={
                      <i
                        title="Exclude Wheelchair Accessible"
                        className="fa fa-wheelchair"
                      />
                    }
                    value={"2"}
                  />
                  <AttributeButton
                    attributes={selectedPricing.attributes}
                    toggleAttributes={toggleAttributes}
                    name={
                      <i title="Alcohol-Free Section" className="fa fa-glass" />
                    }
                    value={"3"}
                  />
                  <AttributeButton
                    attributes={selectedPricing.attributes}
                    toggleAttributes={toggleAttributes}
                    name={
                      <img
                        alt={"PiggyBack Seats"}
                        width={20}
                        src="/assets/media/piggybank.svg"
                      />
                    }
                    value={"5"}
                  />
                  <AttributeButton
                    attributes={selectedPricing.attributes}
                    toggleAttributes={toggleAttributes}
                    name={
                      <i title="Parking Pass Included" className="fa fa-car" />
                    }
                    value={"4"}
                  />
                  <AttributeButton
                    attributes={selectedPricing.attributes}
                    toggleAttributes={toggleAttributes}
                    name={<i title="Aisle Seats" className="fa fa-flag-o" />}
                    value={"6"}
                  />
                </div>
              </div>

              <div style={{ marginTop: 10 }} className={"row"}>
                <div
                  style={{ marginBottom: 10, alignSelf: "center" }}
                  className={"col-lg-2"}
                >
                  <button
                    className={"btn btn-success"}
                    disabled={invalidButton ? "disabled" : false}
                    type={"button"}
                    onClick={onSubmit}
                  >
                    Save
                  </button>
                </div>

                <div className={"col-lg-10"}>
                  {floor > 0 &&
                    comparable &&
                    floor <= 0.4 * comparable.pricePerProduct.amount && (
                      <div
                        style={{ margin: 0, marginBottom: 10 }}
                        className="alert alert-solid-brand alert-bold"
                        role="alert"
                      >
                        <div className="alert-text">
                          Your floor is at least 40% lower than your lowest
                          comparable listing.
                        </div>
                      </div>
                    )}

                  {hasNoSection && (
                    <div
                      style={{ margin: 0, marginBottom: 10 }}
                      className="alert alert-solid-danger alert-bold"
                      role="alert"
                    >
                      <div className="alert-text">
                        You must select at least 1 section
                      </div>
                    </div>
                  )}
                  {invalidSpaceout && (
                    <div
                      style={{ margin: 0, marginBottom: 10 }}
                      className="alert alert-solid-danger alert-bold"
                      role="alert"
                    >
                      <div className="alert-text">
                        Spaceout must be 0 or higher
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
