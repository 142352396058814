import React from "react";

export const BroadcastButton = function({ id, isBroadcast, onAction }) {
  let icon = "fa-check-circle";
  let color = "#1dc9b7";
  if (!isBroadcast) {
    icon = "fa-ban";
    color = "red";
  }

  return (
    <i
      onClick={evt => evt.stopPropagation()}
      onDoubleClick={() => {
        onAction({ id, broadcast: !isBroadcast });
      }}
      style={{ fontSize: 18, color,cursor: "pointer" }}
      className={`fa broadcast-icon ${icon}`}
    />
  );
};