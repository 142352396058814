import React from "react";
import { formatSales } from "./utils/format_sales";

function SalesRow({sale}) {

  return (
    <tr className="kt-datatable__row">
      <td className="kt-datatable__cell">{formatSales(sale)}</td>
      <td className="kt-datatable__cell">{sale.q}</td>
      <td className="kt-datatable__cell">{sale.s}</td>
      <td className="kt-datatable__cell">{sale.r}</td>
      <td className="kt-datatable__cell">{sale.bp.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
      </td>
    </tr>
  )
}

export default SalesRow;