import React from "react";
import { useStoreState } from "easy-peasy";
import AddEventNote from "./AddEventNote";
import { Note } from "./Note";
import {
  NOTE_FULL_HEIGHT,
  NOTE_FULL_VIEW,
  NOTE_SMALL_HEIGHT
} from "../../Store/LayoutStore";

export default function NotesList() {
  const notes = useStoreState(state => state.events.notesSorted);
  const currentEvent = useStoreState(state => state.events.selectedEvent);
  const noteView = useStoreState(state => state.layout.noteView);

    const selectedEvent = useStoreState(state => state.events.selectedEvent);
    const isLoadingEvent = useStoreState(state => state.events.loading);

    if (!isLoadingEvent && !selectedEvent) {
        return (
            <div
                style={{
                    marginTop: 10,
                    height:
                        noteView === NOTE_FULL_VIEW ? NOTE_FULL_HEIGHT : NOTE_SMALL_HEIGHT
                }}
                className="kt-portlet"
            >
                <div
                    style={{
                        margin: "auto",
                        fontSize: 28,
                        fontWeight: 400
                    }}
                    className="kt-portlet__body"
                >
          <span>
            <i className="fa fa-ban" /> No event Selected
          </span>
                </div>
            </div>
        );
    }



  return (
    <div className="kt-portlet__body">
      <div
        style={{
          height: `calc(100vh - 717px - 110px + ${
            noteView !== NOTE_FULL_VIEW
              ? NOTE_FULL_HEIGHT - NOTE_SMALL_HEIGHT
              : 0
          }px)`,
          overflowY: "auto",
          overflowX: "hidden"
        }}
        className="kt-notes"
      >
        <div className="kt-notes__items">
          {notes.map(note => {
            return <Note key={`note-${note.id}`} note={note} />;
          })}
        </div>
      </div>

      <div style={{ height: 105, marginTop: 5 }}>
        {currentEvent && <AddEventNote eventId={currentEvent.id} />}
      </div>
    </div>
  );
}
