import { action, computed, thunk, thunkOn } from "easy-peasy";
import { GroupsStore } from "../types";

const DEFAULT_GROUP = {
  id: null,
  name: "",
  spaceout_amount: 5.0,
  spaceout_type: 1
};

export const GroupStore: GroupsStore = {
  isLoading: false,
  items: {},
  itemsArray: computed(state => {
    return Object.keys(state.items).map((key: any) => {
      return state.items[key];
    });
  }),
  listings: {},
  listingsArray: computed(state => {
    return Object.keys(state.listings).reduce((acc: any, key: any) => {
      return [...acc, ...state.listings[key]];
    }, []);
  }),
  setToLoading: action(state => {
    state.isLoading = true;
  }),
  selectedGroup: null,

  unselectGroup: action((state, payload) => {
    state.selectedGroup = null;
  }),
  unselectAll: action(state => {
    state.selectedGroup = null;
    state.items = {};
    state.listings = {};
  }),
  getSelectedGroupId: computed(
    [state => state.selectedGroup],
    selectedGroup => {
      if (selectedGroup) {
        return selectedGroup.id;
      }
      return null;
    }
  ),
  selectGroup: action((state, payload) => {
    state.selectedGroup = payload;
    state.selectedGroup.listings = state.listings[payload.id]
      ? state.listings[payload.id]
      : [];
  }),
  createGroup: action((state, payload) => {
    state.selectedGroup = { ...DEFAULT_GROUP, event_id: payload };
    state.selectedGroup.listings = [];
  }),

  addListingsInGroup: action((state, payload) => {
    const listings = [
      ...state.selectedGroup.listings,
      ...payload.map((listing_id: string) => {
        return { listing_id, group_id: state.selectedGroup.id };
      })
    ];
    state.selectedGroup.listings = listings;
  }),

  deleteListingFromGroupThunk: thunk(async (actions, payload) => {
    actions.deleteListingFromGroup(payload);
    await fetch(`/api/group/listing/delete`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });
  }),
  deleteListingFromGroup: action((state, payload: any) => {
    const newListings = { ...state.listings };
    Object.keys(newListings).forEach(group => {
      newListings[group] = newListings[group].filter(
        (l: { listing_id: any }) => !payload.includes(l.listing_id)
      );
    });
    state.listings = newListings;
    state.selectedGroup = null;
  }),

  removeListingInGroup: action((state, payload) => {
    const listings = [...state.selectedGroup.listings].filter(
      l => l.listing_id !== payload
    );
    state.selectedGroup.listings = listings;
  }),
  availableListingsInGroups: computed(
    [
      (_, store) => {
        return store.listings.itemsArray;
      },
      state => {
        return state.listingsArray;
      },
      state => {
        return state.selectedGroup ? state.selectedGroup.listings : [];
      },
      state => (state.selectedGroup ? state.selectedGroup.id : null),
      (_, store) => store.pricing.itemsArray
    ],
    (allListings, itemsInGroups, itemsInSelectedGroup, groupId, pricings) => {
      return allListings
        .filter((listing: { id: any }) => {
          return !pricings.some(
            (pricing: { listing_id: any }) => pricing.listing_id === listing.id
          );
        })
        .filter((listing: { id: any }) => {
          return !itemsInGroups.some(
            (itemInGroup: { listing_id: any; group_id: any }) =>
              itemInGroup.listing_id === listing.id &&
              itemInGroup.group_id !== groupId
          );
        })
        .filter((listing: { id: any }) => {
          return !itemsInSelectedGroup.some(
            (itemInGroup: { listing_id: any }) => {
              return itemInGroup.listing_id === listing.id;
            }
          );
        });
    }
  ),

  sortInGroups: action((state, payload) => {
    const sortedListings = state.selectedGroup.listings.sort(
      (a: any, b: any) => {

        let aValue = payload.allListings[a.listing_id][payload.sortField];
        let bValue = payload.allListings[b.listing_id][payload.sortField];

        if (!isNaN(parseInt(aValue)) && !isNaN(parseInt(bValue))){
          aValue = parseInt(aValue)
          bValue = parseInt(bValue)
        }

        return aValue <
          bValue
          ? payload.sortOrder === "DESC"
            ? 1
            : -1
          : payload.sortOrder === "DESC"
          ? -1
          : 1;
      }
    );

    state.selectedGroup.listings = sortedListings;
  }),

  sortInGroupsThunk: thunk((actions, payload, { getStoreState }) => {
    actions.sortInGroups({
      ...payload,
      // @ts-ignore
      allListings: getStoreState().listings.items
    });
  }),

  reorderListingsInGroups: action((state, payload) => {
    const newArray = [...state.selectedGroup.listings];
    newArray.splice(
      payload.endIndex,
      0,
      newArray.splice(payload.startIndex, 1)[0]
    );
    state.selectedGroup.listings = newArray;
  }),

  updateGroupThunk: thunk(async (actions, payload) => {
    const response = await fetch(`/api/group`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const jsonData = await response.json();
    actions.updateGroup({ ...payload, listings: jsonData.listings });
    // @ts-ignore
    await actions.loadGroupsThunk({ id: payload.group.event_id, reload: true });
  }),

  deleteGroupThunk: thunk(async (actions, payload) => {
    actions.deleteGroup(payload);
    await fetch(`/api/group/${payload.groupId}`, { method: "DELETE" });
  }),
  deleteGroup: action((state, { groupId }) => {
    const newGroups: any = {};
    Object.keys(state.items).forEach(key => {
      if (key.toString() !== groupId.toString()) {
        newGroups[key] = state.items[key];
      }
    });

    state.items = newGroups;
    state.listings[groupId] = [];
  }),

  // Need this for action listener in ListingsStore
  updateGroup: action((state, payload) => {}),

  loadGroupsThunk: thunk(async (actions, payload) => {
    if (!payload.reload) {
      actions.setToLoading();
    }
    const response = await fetch(`/api/group/${payload.id}`);
    const jsonData = await response.json();
    actions.loadGroups(jsonData);
  }),
  loadGroups: action((state, payload) => {
    const groupItems: any = {};
    const listingsItem: any = {};

    Object.keys(payload).forEach(groupId => {
      const { listings, ...groupData } = payload[groupId];
      groupItems[groupId] = groupData;
      listingsItem[groupId] = listings;
    });

    state.items = groupItems;
    state.listings = listingsItem;
    state.isLoading = false;
  }),

  changeGroupValue: action((state, { key, value }) => {
    if (!state.selectedGroup) {
      return;
    }
    state.selectedGroup[key] = value;
  }),

  onLoadEvent: thunkOn(
    (actions, store) => store.events.loadEventDataThunk.startType,
    async (actions, target) => {
      actions.unselectAll();
      // @ts-ignore
      actions.loadGroupsThunk({ id: target.payload.id });
    }
  )
};
