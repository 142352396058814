import React from "react";

export default function SplitButton({ name, splits, toggleSplits }) {
  let isActive = splits.includes(name);
  if (name === "All") {
    isActive = splits.length === 0;
  }

  return (
    <button
      style={{ padding: 2, height: 30 }}
      type="button"
      onClick={() => toggleSplits(name.toString())}
      className={`btn btn-sm btn-${isActive ? "primary" : "secondary"}`}
    >
      {name}
    </button>
  );
}
