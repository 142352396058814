import React, {useState} from "react";
import { useStoreState } from "easy-peasy";
import RelatedListingsList from "./RelatedListingsList";
import CustomMapping from "./CustomMapping";

export default React.memo(function RelatedListings() {
  const selectedPricing = useStoreState(state => state.pricing.selectedPricing)
  const listingView = useStoreState(state => state.layout.listingView);

  const isLoading = useStoreState(
    state => state.stubhub_listings.isLoading
  );

  const criteriaPrice = useStoreState(state => state.pricing.criteriaPrice);
  const numberOfListings = useStoreState(state => state.stubhub_listings.numberOfListingsFiltered);
  const numberOfTickets = useStoreState(state => state.stubhub_listings.numberOfTicketsFiltered);


  const [isMapping, setIsMapping] = useState(false);

  if (isMapping) {
      return <CustomMapping setIsMapping={setIsMapping} />
  }

  return (
    <div style={{ marginTop: 10 }} className="kt-portlet kt-portlet--tab">
      <div className="kt-portlet__head">
        <div style={{width:"100%", justifyContent:"space-between"}} className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
                <i
                    onClick={(evt) => {
                        setIsMapping(true)
                        evt.stopPropagation()
                    }}
                    style={{fontSize: 16, marginRight: 9, cursor: "pointer"}}
                    className={"fa fa-gear"}
                />

                Related Listings</h3>
            <h3 className="kt-portlet__head-title">{selectedPricing && !isLoading && `${numberOfListings} (${numberOfTickets})`}</h3>

            {(criteriaPrice || criteriaPrice > 0) && (
                <div
                    style={{
                        fontSize: "36px",
                        fontWeight: 500
                    }}
                >
                {criteriaPrice.toFixed(2)}$
            </div>
          )}
        </div>
      </div>

      <div className="kt-portlet__body kt-portlet__body--fit">
        {isLoading && selectedPricing && (
          <div style={{ width: "100%", height: "300px", display: "flex" }}>
            <div style={{ margin: "auto" }}>
              <div className="kt-spinner kt-spinner--md kt-spinner--info"></div>
            </div>
          </div>
        )}

        {!selectedPricing && (
          <div style={{ width: "100%", height: "300px", display: "flex" }}>
            <div style={{ margin: "auto" }}>
              <span style={{ fontSize: 28, fontWeight: 400 }}>
                <i className="fa fa-ban" /> No{" "}
                {listingView === "LISTING_VIEW" ? "listing" : "group"} selected
              </span>
            </div>
          </div>
        )}

        {!isLoading && selectedPricing && <RelatedListingsList/>}
      </div>
    </div>
  );
});
