// @ts-ignore
import { isBetweenRowMinAndMax} from "@jfortin/kobe-criteria";
import { ListingsStore } from "../types";
import { action, actionOn, computed, thunk, thunkOn } from "easy-peasy";

export const ListingStore: ListingsStore = {
  customPrices: {},
  items: {},
  itemsArray: computed([state => state.items], items => {
    return Object.keys(items).map((key: any) => {
      return items[key];
    });
  }),

  resetAllPrices: action(state => {
    state.customPrices = {};
  }),
  resetPrice: action((state, payload) => {
    const newPrices = { ...state.customPrices };
    payload.forEach(id => {
      if (newPrices[id]) {
        delete newPrices[id];
      }
    });
    state.customPrices = newPrices;
  }),
  setCustomPrice: action((state, payload) => {
    state.customPrices[payload.id] = payload.price;
  }),

  getCustomPriceById: computed(
    [state => state.customPrices],
    customPrices => (listingId: any) => {
      if (customPrices[listingId]) {
        return customPrices[listingId];
      }
      return null;
    }
  ),

  getFilteredListings: computed(
    [
      state => state.itemsArray,
      (_, store) => store.events.selectedSections,
      (_, store) => store.pricing.pricingByListingId,
      state => state.filtersDecoded
    ],
    (itemsArray, filteredSections, pricingByListingId, filters) => {
      return itemsArray.filter((listing: any) => {
        if (
          filteredSections.length > 0 &&
          !filteredSections.some(section =>
            listing.section.toLowerCase().includes(section.toLowerCase())
          )
        ) {
          return false;
        }

        if (filters.length <= 0) {
          return true;
        }

        const pricing = pricingByListingId(listing.id);

        return filters.every((filter: any) => {
          switch (filter.id) {
            case "quantity":
              if (filter.value.minimum >= 0) {
                return (
                  listing.quantity >= filter.value.minimum &&
                  listing.quantity <= filter.value.maximum
                );
              }
              if (
                !filter.value
                  .map((v: { toString: () => any }) => v.toString())
                  .includes(listing.quantity.toString())
              ) {
                return false;
              }
              return true;
            case "split":
              if (!pricing) {
                return false;
              }
              const splits = pricing.split.map((v: any) => parseInt(v));

              if (filter.value.minimum >= 0) {
                return splits.some(
                  (split: any) =>
                    split >= filter.value.minimum &&
                    split <= filter.value.maximum
                );
              }

              return filter.value
                .map((v: any) => parseInt(v))
                .some((fil: any) => splits.includes(parseInt(fil)));
            case "row":
            case "section":
              if (filter.value.minimum !== undefined) {
                return isBetweenRowMinAndMax(
                  filter.value.minimum,
                  filter.value.maximum,
                  listing[filter.id]
                );
              }

              if (Array.isArray(filter.value)) {
                return filter.value.some((sec: any) =>
                  listing[filter.id].toLowerCase().includes(sec)
                );
              }

              return listing[filter.id]
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            default:
              break;
          }

          return true;
        });
      });
    }
  ),
  getListingsByGroup: computed(
    [state => state.getFilteredListings, (_, store) => store.groups.listings],
    (items, groupListings) => {
      const ungroupedItems = items
        .filter(
          (listing: any) =>
            !Object.keys(groupListings).some(groupKey =>
              groupListings[groupKey].some(
                (groupListing: any) => groupListing.listing_id === listing.id
              )
            )
        )
        .map((l: any) => {
          return { listing_id: l.id };
        });

      return { 0: ungroupedItems, ...groupListings };
    }
  ),
  getGroupIdByListingId: computed(
    [(_, store) => store.groups.listings],
    groupListings => (listingId: any) => {
      const findGroup = Object.keys(groupListings).filter(groupId => {
        return groupListings[groupId].some(
          (listing: any) => listing.listing_id === listingId
        );
      });

      return findGroup.length > 0 ? parseInt(findGroup[0]) : 0;
    }
  ),
  checkedListings: [],
  checkedGroups: [],
  filters: [],
  isListingChecked: computed(
    [state => state.checkedListings],
    checkedListings => (listingId: any) => {
      return checkedListings.includes(listingId);
    }
  ),
  getSelectedListingId: computed(
    [state => state.selectedListing],
    selectedListing => {
      if (selectedListing) {
        return selectedListing.id;
      }
      return null;
    }
  ),
  isGroupChecked: computed(
    [state => state.checkedGroups],
    checkedGroups => (groupId: any) => {
      return checkedGroups.includes(groupId);
    }
  ),
  getCheckedListings: computed(
    [state => state.checkedListings, state => state.items],
    (checkedListings, items) => {
      return checkedListings
        .map((key: any) => {
          return items[key];
        })
        .filter(v => !!v);
    }
  ),
  uncheckListing: action((state, payload) => {
    if (state.checkedListings.includes(payload)) {
      state.checkedListings = state.checkedListings.filter(v => v !== payload);
    }
  }),
  checkListing: action((state, payload) => {
    if (!state.checkedListings.includes(payload)) {
      state.checkedListings.push(payload);
    }
  }),

  uncheckAllListings: action((state, payload) => {
    state.checkedListings = [];
    state.checkedGroups = [];
  }),

  checkAllListings: action((state, payload) => {
    state.getFilteredListings.forEach((listing: any) => {
      if (!state.checkedListings.includes(listing.id)) {
        state.checkedListings.push(listing.id);
      }

      payload.forEach((group: any) => {
        if (!state.checkedGroups.includes(group.id)) {
          state.checkedGroups.push(group.id);
        }
      });

      // Ungrouped group
      if (!state.checkedGroups.includes(0)) {
        state.checkedGroups.push(0);
      }
    });
  }),

  multiselectListings: action((state, payload) => {
    if (state.checkedListings.length <= 0) {
      state.checkedListings.push(payload);
      return;
    }
    const allListingsCheckboxes: any = Array.from(
      document.getElementsByClassName("listing-checkbox")
    );
    const maxIndexOfCheckedIndex = allListingsCheckboxes.reduce(
      (acc: any, curr: any, idx: number) => {
        if (state.checkedListings.includes(parseInt(curr.dataset.id))) {
          return idx > acc ? idx : acc;
        }
        return acc;
      },
      -1
    );
    const indexOfSelectedListing = allListingsCheckboxes.findIndex(
      (listing: any) => parseInt(listing.dataset.id) === parseInt(payload)
    );
    if (maxIndexOfCheckedIndex < indexOfSelectedListing) {
      for (
        let i = maxIndexOfCheckedIndex + 1;
        i <= indexOfSelectedListing;
        i += 1
      ) {
        const listingId = parseInt(allListingsCheckboxes[i].dataset.id);
        if (!state.checkedListings.includes(listingId)) {
          state.checkedListings.push(listingId);
        }
      }
    }
  }),

  onToggleListing: actionOn(
    actions => [actions.checkListing, actions.uncheckListing],
    (state, target) => {
      switch (target.type) {
        case "@action.listings.checkListing":
          const group = state.getGroupIdByListingId(target.payload);
          if (state.getListingsByGroup[group]) {
            if (
              state.getListingsByGroup[group].every(
                (groupListing: any) =>
                  state.checkedListings.includes(groupListing.listing_id) ||
                  groupListing.listing_id === target.payload ||
                  !state.items[groupListing.listing_id]
              )
            ) {
              if (!state.checkedGroups.includes(group)) {
                state.checkedGroups.push(group);
              }
            }
          }
          break;
        case "@action.listings.uncheckListing":
          const uncheckGroup = state.getGroupIdByListingId(target.payload);
          if (state.checkedGroups.includes(uncheckGroup)) {
            state.checkedGroups = state.checkedGroups.filter(
              v => v !== uncheckGroup
            );
          }
          break;
      }
    }
  ),

  onToggleGroup: actionOn(
    actions => [actions.uncheckGroup, actions.checkGroup],
    (state, target) => {
      switch (target.type) {
        case "@action.listings.checkGroup":
          if (!state.getListingsByGroup[target.payload]) {
            return;
          }
          state.getListingsByGroup[target.payload].forEach(
            (groupListing: any) => {
              if (!state.checkedListings.includes(groupListing.listing_id)) {
                state.checkedListings.push(groupListing.listing_id);
              }
            }
          );
          break;
        case "@action.listings.uncheckGroup":
          if (!state.getListingsByGroup[target.payload]) {
            return;
          }
          state.getListingsByGroup[target.payload].forEach(
            (groupListing: any) => {
              if (state.checkedListings.includes(groupListing.listing_id)) {
                state.checkedListings = state.checkedListings.filter(
                  listing => listing !== groupListing.listing_id
                );
              }
            }
          );
          break;
      }
    }
  ),

  uncheckGroup: action((state, payload) => {
    if (state.checkedGroups.includes(payload)) {
      state.checkedGroups = state.checkedGroups.filter(v => v !== payload);
    }
  }),
  checkGroup: action((state, payload) => {
    if (!state.checkedGroups.includes(payload)) {
      state.checkedGroups.push(payload);
    }
  }),

  onFilterChanged: actionOn(
    (actions, storeActions) => [
      actions.addFilter,
      actions.removeFilter,
      storeActions.events.toggleMapFilter
    ],
    state => {
      state.checkedListings = [];
      state.checkedGroups = [];
    }
  ),

  filtersDecoded: computed(state => {
    return state.filters.map((filter: any) => {
      const commas = filter.value.split(",");
      const hyphens = filter.value.split("-");

      const addCommas = () => {
        return {
          ...filter,
          value: commas.map((v: string) => v)
        };
      };

      const addHyphens = () => {
        let minimum = hyphens[0];
        let maximum = hyphens[hyphens.length - 1];

        if (isNaN(parseInt(minimum)) || isNaN(parseInt(maximum))) {
          return {
            ...filter,
            value: {
              minimum,
              maximum
            }
          };
        }

        if (parseInt(minimum) > parseInt(maximum)) {
          let temp = minimum;
          minimum = maximum;
          maximum = temp;
        }

        const values = {
          minimum: parseInt(minimum),
          maximum: parseInt(maximum)
        };

        return {
          ...filter,
          value: values
        };
      };

      switch (filter.id) {
        case "row":
        case "section":
          if (commas.length > 1) {
            return addCommas();
          }
          if (hyphens.length > 1) {
            return addHyphens();
          }
          return filter;
        case "quantity":
        case "split":
          if (commas.length > 1) {
            return addCommas();
          }
          if (hyphens.length > 1) {
            return addHyphens();
          }

          return {
            ...filter,
            value: [parseInt(filter.value)]
          };
        default:
          return filter;
      }
    });
  }),

  addFilter: action((state, payload) => {
    const newFilters = state.filters.filter(filter => {
      return payload.id !== filter.id;
    });

    newFilters.push(payload);

    state.filters = newFilters;
  }),

  removeFilter: action((state, payload) => {
    const newFilters = state.filters.filter(filter => {
      return payload !== filter.id;
    });

    state.filters = newFilters;
  }),

  onUpdatePricing: actionOn(
    (actions, store) => store.pricing.updatePricing,
    (state, target) => {
      if (state.items[target.payload.listing_id] && target.payload.cmp) {
        state.items[
          target.payload.listing_id
        ].price = target.payload.cmp.toFixed(2);
      }
    }
  ),

  onUpdateGroupPricing: actionOn(
    (actions, store) => store.groups.updateGroup,
    (state, target) => {
      target.payload.listings.forEach((listing: any) => {
        if (state.items[listing.id]) {
          // @ts-ignore
          state.items[listing.id].price = parseFloat(listing.price).toFixed(2);
        }
      });
    }
  ),
  onLoadEvent: thunkOn(
    (actions, store) => store.events.loadEventDataThunk.startType,
    async (actions, target) => {
      actions.unselectAll();
      actions.loadListingsThunk({ eventId: target.payload.id });
    }
  ),
  unselectAll: action((state, payload) => {
    state.items = {};
    state.customPrices = {};
    state.checkedListings = [];
    state.checkedGroups = [];
    state.selectedListing = null;
    state.filters = [];
  }),
  loadListingsThunk: thunk(async (actions, payload) => {
    if (!payload.reload) {
      actions.setToLoading();
    }
    const response = await fetch(`/api/listings/${payload.eventId}`);
    const jsonData: any = await response.json();
    actions.loadListings(jsonData);
  }),
  loadListings: action((state, payload) => {
    state.isLoading = false;
    state.items = {};
    payload.forEach(listing => {
      const listingId = listing.id;
      state.items[listingId] = listing;
    });
  }),
  isLoading: false,
  selectedListing: null,
  unselectListing: action(state => {
    state.selectedListing = null;
  }),

  setToLoading: action(state => {
    state.isLoading = true;
  }),

  selectListing: action((state, payload) => {
    if (state.selectedListing && state.selectedListing.id === payload.id) {
      return;
    }
    state.selectedListing = state.items[payload.id];
  }),
  changeBroadcastTypeThunk: thunk(async (actions, payload) => {
    actions.changeBroadcastType(payload);
    await fetch(`/api/listings/broadcast`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    });
  }),
  changeBroadcastType: action((state, payload) => {
    state.items[payload.id].is_broadcast = payload.broadcast;
  }),

  changePriceOnSkybox: action((state, payload) => {
    payload.forEach(({ id, price }) => {
      state.items[id].price = parseFloat(price);
    });
  }),
  changePriceOnSkyboxThunk: thunk(async (actions, payload) => {
    actions.resetPrice(payload.map(k => k.id));
    actions.changePriceOnSkybox(payload);
    await fetch(`/api/pricing/skyboxprice`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    });
  }),

  updateListingThunk: thunk(async (actions, payload) => {
    try {
      await fetch(`/api/listings`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json"
        }
      });
      actions.updateListing(payload);
    } catch {
      console.log("Error!");
    }
  }),
  updateListing: action((state, payload) => {
    state.items[payload.id] = payload;
  })
};
