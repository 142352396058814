import React, { useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./ListingList.css";
import useInterval from "../../utils/use_interval";
import FilterColumn from "./Buttons/FilterColumn";
import FilterButton from "./Buttons/FilterButton";
import SimpleDetailView from "./Buttons/SimpleDetailView";
import GroupedView from "./Buttons/GroupedView";
import ListingsUngrouped from "./ListingsUngrouped";
import ListingsGrouped from "./ListingsGrouped";
import {
  LISTING_TABLE_GROUPED_VIEW,
  LISTING_TABLE_UNGROUPED_VIEW,
} from "../../Store/LayoutStore";
import BulkActionsButton from "./Buttons/BulkActionsButton";
import ColumnRow from "./ColumnRow";
import MarketplacesIcons from "./Icons/MarketplacesIcons";

export default React.memo(function Listings() {
  const listings = useStoreState((state) => state.listings.getFilteredListings);
  const groups = useStoreState((state) => state.groups.itemsArray);

  const isLoadingPricing = useStoreState((state) => state.listings.isLoading);
  const isLoadingGroups = useStoreState((state) => state.groups.isLoading);
  const isLoading = isLoadingPricing || isLoadingGroups;

  const filteredSections = useStoreState(
    (state) => state.events.selectedSections
  );

  const filters = useStoreState((state) => state.listings.filters);

  const toggleMapFilter = useStoreActions(
    (actions) => actions.events.toggleMapFilter
  );
  const clearMapFilters = useStoreActions(
    (actions) => actions.events.clearMapFilters
  );

  const switchToSkyboxView = useStoreActions(
    (actions) => actions.layout.changeToSkyboxView
  );

  const removeFilter = useStoreActions(
    (actions) => actions.listings.removeFilter
  );
  const addFilter = useStoreActions((actions) => actions.listings.addFilter);

  const eventId = useStoreState((state) => state.events.eventId);
  const sgId = useStoreState((state) => state.events.seatgeekId);
  const tmId = useStoreState((state) => state.events.ticketmasterId);
  const shId = useStoreState((state) => state.events.stubhubId);

  const tableView = useStoreState((state) => state.layout.listingTableView);

  const listingsChecked = useStoreState(
    (state) => state.listings.checkedListings
  );
  const isAnyListingChecked = listingsChecked.length > 0;

  const checkAllListings = useStoreActions(
    (actions) => actions.listings.checkAllListings
  );
  const uncheckAllListings = useStoreActions(
    (actions) => actions.listings.uncheckAllListings
  );

  const submitFilter = (id, name) => {
    return (value) => {
      addFilter({ id, name, value });
    };
  };

  const [{ sortField, sortOrder }, setSort] = useState({
    sortField: "price",
    sortOrder: "ASC",
  });

  const sortFunc = (field) => {
    if (field === sortField) {
      if (sortOrder === "DESC") {
        setSort({ sortField, sortOrder: "ASC" });
        return;
      }
      setSort({ sortField, sortOrder: "DESC" });
      return;
    }
    setSort({ sortField: field, sortOrder: "ASC" });
  };

  const sortedListings = listings.sort((a, b) => {
    let aValue = a[sortField];
    let bValue = b[sortField];

    if (!isNaN(parseFloat(a[sortField])) && !isNaN(parseFloat(a[sortField]))) {
      aValue = parseFloat(a[sortField]);
      bValue = parseFloat(b[sortField]);
    }

    return aValue < bValue
      ? sortOrder === "DESC"
        ? 1
        : -1
      : sortOrder === "DESC"
      ? -1
      : 1;
  });

  const reloadListingsThunk = useStoreActions(
    (actions) => actions.listings.loadListingsThunk
  );
  const reloadPricingThunk = useStoreActions(
    (actions) => actions.pricing.loadPricingsThunk
  );

  const onTabPress = (id) => {
    const activePriceElements = Array.from(
      document.querySelectorAll(".price-inline-update")
    );
    const idFound = activePriceElements.findIndex(
      (element) => element.dataset.id.toString() === id.toString()
    );
    let idxToFocus = 0;
    if (idFound + 1 < activePriceElements.length) {
      idxToFocus = idFound + 1;
    }
    activePriceElements[idxToFocus].focus();
  };

  useInterval(() => reloadListingsThunk({ eventId, reload: true }), 30000);
  useInterval(() => reloadPricingThunk({ eventId, reload: true }), 30000);

  return (
    <>
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 10,
          width: "100%",
          userSelect: "none",
        }}
        className="row"
      >
        <div className="col-lg-2">
          <div
            style={{ padding: 0, width: 100 }}
            className="btn-group btn-group-sm"
          >
            <SimpleDetailView />
            <GroupedView />
          </div>
        </div>

        <div className="col-lg-10">
          <div style={{ float: "right" }} className="btn-group">
          
            <MarketplacesIcons sgId={sgId} tmId={tmId} shId={shId} vividId={eventId}/>
            <button
              style={{marginRight:15}}
              className={"btn btn-success"}
              type={"button"}
              onClick={switchToSkyboxView}
            >
              Add Listing
            </button>
            <BulkActionsButton isAnyListingChecked={isAnyListingChecked} />
          </div>
        </div>
      </div>
      <div
        style={{
          marginLeft: 25,
          marginTop: filteredSections.length > 0 || filters.length > 0 ? 10 : 0,
          width: "96%",
        }}
        className="row"
      >
        <div style={{ paddingLeft: 0 }} className="col-lg-12">
          {(filteredSections.length > 0 || filters.length > 0) && (
            <>
              {filteredSections.length > 0 && (
                <button
                  style={{ marginRight: 5 }}
                  className={"btn btn-google btn-sm"}
                  onClick={clearMapFilters}
                >
                  Clear
                </button>
              )}

              {filteredSections.map((section) => (
                <FilterButton
                  key={`map-section-${section}`}
                  value={section}
                  name={"Map"}
                  onDelete={() => toggleMapFilter(section)}
                />
              ))}

              {filters.map(({ id, name, value }) => (
                <FilterButton
                  key={`filter-${id}`}
                  value={value}
                  name={name}
                  onDelete={() => removeFilter(id)}
                />
              ))}
            </>
          )}
        </div>
      </div>

      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table
          className="kt-datatable__table"
          width="100%"
          style={{ display: "table" }}
        >
          <thead
            style={{ display: "table-header-group" }}
            className="kt-datatable__head"
          >
            <tr className="kt-datatable__row">
              <th>
                {listings.length > 0 && (
                  <label
                    onClick={(evt) => evt.stopPropagation()}
                    className="kt-checkbox kt-checkbox--single kt-checkbox--solid"
                  >
                    <input
                      onChange={(evt) => {
                        if (evt.target.checked) {
                          checkAllListings(groups);
                        } else {
                          uncheckAllListings();
                        }
                      }}
                      checked={listingsChecked.length === listings.length}
                      type="checkbox"
                    />
                    &nbsp;
                    <span
                      style={{
                        width: 20,
                        height: 20,
                        top: 4,
                        left: 3,
                      }}
                    ></span>
                  </label>
                )}
              </th>

              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <div className="filterColumn">
                  <span>Split</span>
                  <FilterColumn onSubmit={submitFilter("split", "Split")} />
                </div>
              </th>

              <ColumnRow
                name={"Qty"}
                value={"quantity"}
                sortField={sortField}
                sortFunc={sortFunc}
                sortOrder={sortOrder}
                filterColumn={
                  <FilterColumn onSubmit={submitFilter("quantity", "Qty")} />
                }
              />
              <ColumnRow
                name={"Section"}
                value={"section"}
                sortField={sortField}
                sortFunc={sortFunc}
                sortOrder={sortOrder}
                filterColumn={
                  <FilterColumn onSubmit={submitFilter("section", "Section")} />
                }
              />
              <ColumnRow
                name={"Row"}
                value={"row"}
                sortField={sortField}
                sortFunc={sortFunc}
                sortOrder={sortOrder}
                filterColumn={
                  <FilterColumn onSubmit={submitFilter("row", "Row")} />
                }
              />

              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Seats</span>
              </th>

              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Type</span>
              </th>

              <ColumnRow
                name={"Cost"}
                value={"cost"}
                sortField={sortField}
                sortFunc={sortFunc}
                sortOrder={sortOrder}
              />
              <ColumnRow
                name={"Price"}
                value={"price"}
                sortField={sortField}
                sortFunc={sortFunc}
                sortOrder={sortOrder}
              />
              <ColumnRow
                name={"Days Old"}
                value={"days_old"}
                sortField={sortField}
                sortFunc={sortFunc}
                sortOrder={sortOrder}
              />
              
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>CMP</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Floor</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Ceil</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Inc</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Action</span>
              </th>
            </tr>
          </thead>

          <tbody
            style={{ display: "table-row-group" }}
            className="kt-datatable__body"
          >
            {isLoading && (
              <tr>
                <td style={{ width: "40vw", height: 75 }} colSpan={44}>
                  <div style={{ position: "relative", left: "17vw", top: 0 }}>
                    <div className="kt-spinner kt-spinner--md kt-spinner--info"></div>
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && tableView === LISTING_TABLE_UNGROUPED_VIEW && (
              <ListingsUngrouped
                onTabPress={onTabPress}
                groups={groups}
                listings={sortedListings}
              />
            )}
            {!isLoading && tableView === LISTING_TABLE_GROUPED_VIEW && (
              <ListingsGrouped
                onTabPress={onTabPress}
                groups={groups}
                listings={sortedListings}
              />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
});
