import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { DeleteCriteriaButton } from "../Listings/Buttons/DeleteCriteriaButton";
import { IncrementTypeIcon } from "../Listings/Icons/IncrementTypeIcon";

export const GroupRow = ({ eventId, group, listings }) => {
  const selectGroup = useStoreActions(actions => actions.groups.selectGroup);

  const pricing = useStoreState(state =>
    state.pricing.pricingById(group.pricing_id)
  );

  const deleteGroup = useStoreActions(
    actions => actions.groups.deleteGroupThunk
  );

  let highlightColor = "none";

  if (!pricing) {
    return null;
  }

  return (
    <tr
      style={{
        cursor: "pointer",
        backgroundColor: highlightColor
      }}
      key={eventId}
      onClick={() => {
        selectGroup(group);
      }}
      className="kt-datatable__row"
    >
      <td className="kt-datatable__cell">
        <span>{group.name}</span>
      </td>
      <td className="kt-datatable__cell">
        <span>{listings.length}</span>
      </td>
      <td className="kt-datatable__cell">
        <span>${pricing.cmp}</span>
      </td>
      <td className="kt-datatable__cell">
        <span>${pricing.floor}</span>
      </td>
      <td className="kt-datatable__cell">
        <span>${pricing.ceiling}</span>
      </td>
      <td className="kt-datatable__cell">
        <span>
          <IncrementTypeIcon
            amount={pricing.increment_amount}
            value={pricing.increment_type}
          />
        </span>
      </td>
      <td className="kt-datatable__cell">
        <span>
          {group.spaceout_type === 1 ? "%" : "$"}
          {group.spaceout_amount}
        </span>
      </td>
      <td className="kt-datatable__cell">
        <DeleteCriteriaButton
          onAction={() => {
            deleteGroup({ groupId: group.id, pricingId: group.pricing_id });
          }}
          key={group.id}
        />
      </td>
    </tr>
  );
};
