import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

export const GeneralAdmissionMap = () => {
  const selectedEvent = useStoreState(state => state.events.selectedEvent);
  const svgData = useStoreState(state => state.events.mapSVG);
  const selectedListingSection = useStoreState(state =>
    state.pricing.selectedPricing ? state.pricing.selectedPricing.sections : []
  );

  const allPricing = useStoreState(state => state.pricing.itemsArray);
  const relatedListings = useStoreState(state => state.stubhub_listings.items);

  const toggleMapFilter = useStoreActions(
    state => state.events.toggleMapFilter
  );
  const toggleMapSection = useStoreActions(
    actions => actions.pricing.toggleMapSection
  );
  const unmatchEvent = useStoreActions(
      actions => actions.events.unmatchEventThunk
  );

  const sectionsSet = new Set();

  const [sectionName, setSectionName] = useState("");
  const [customSections, setCustomSections] = useState([]);

  allPricing.forEach(pricing => {
    pricing.sections.forEach(section => {
      sectionsSet.add(section.toString().toUpperCase());
    });
  });

  relatedListings.forEach(listing => {
    if (listing.sectionName) {
      sectionsSet.add(listing.sectionName.toString().toUpperCase());
    }
  });

  customSections.forEach(section => {
    sectionsSet.add(section.toString().toUpperCase());
  });

  const sections = Array.from(sectionsSet).sort();

  const onSubmit = () => {
    if (sectionName.length > 2) {
      setCustomSections([...customSections, sectionName.toString().toUpperCase()]);
      setSectionName("");
    }
  };

  useEffect(() => {
    setSectionName("");
    setCustomSections([]);
  }, [selectedEvent]);


  return (
    <>
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Map Sections</h3>
        </div>

        <div>
          <button
              onClick={() => {
                unmatchEvent(selectedEvent);
              }}
              style={{ marginTop: 10 }}
              className={"btn btn-outline-danger"}

          >
            <i className="fa fa-chain-broken" aria-hidden="true"></i>
            Unmatch SG
          </button>
        </div>


        {svgData && (
          <div className="kt-portlet__head-label">
            <a href={svgData} target="_blank" rel="noopener noreferrer">
              Open Map
                <i
                  style={{ fontSize: 18, color: "#959cb6", paddingLeft: 5, paddingTop: 2 }}
                  className={`fa fa-external-link`}
                />
              </a>
          </div>
        )}


        
      </div>

      <div style={{ paddingBottom: 0 }} className="kt-portlet__body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: 450,
            justifyContent: "space-between"
          }}
        >
          <div style={{ marginBottom: 0 }} className="kt-section">
            {sections.map(section => (
              <button
                key={section}
                style={{ margin: 5 }}
                onClick={(evt) => {
                  const names = section.split(" ");
                  evt.stopPropagation();
                  if (evt.ctrlKey || evt.metaKey) {
                    toggleMapFilter(names[names.length - 1].toLowerCase());
                    return;
                  }
                 toggleMapSection(section);
                }}
                className={`btn btn-map-section ${
                  selectedListingSection.includes(section) ? "active" : ""
                }`}
              >
                {section}
              </button>
            ))}
          </div>
          <div className={"row"} style={{ marginBottom: 50 }}>
            <div className={"col-lg-10"}>
              <input
                className="form-control"
                name="section-name"
                placeholder="Section"
                type="text"
                value={sectionName}
                onChange={e => setSectionName(e.target.value)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
            </div>
            <div className={"col-lg-2"}>
              <button
                onClick={onSubmit}
                disabled={sectionName.length < 2}
                className={"btn btn-success"}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
