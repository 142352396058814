import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GroupRow } from "./GroupRow";

export default function GroupList() {
  const createGroup = useStoreActions(actions => actions.groups.createGroup);
  const groups = useStoreState(state => state.groups.itemsArray);
  const listingsDictionnary = useStoreState(state => state.groups.listings);
  const eventId = useStoreState(state =>
    state.events.selectedEvent ? state.events.selectedEvent.id : null
  );
  const isLoading = useStoreState(state => state.groups.isLoading);

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
      <table
        style={{ display: "table" }}
        className="kt-datatable__table"
        width="100%"
      >
        <thead
          style={{ display: "table-header-group" }}
          className="kt-datatable__head"
        >
          <tr className="kt-datatable__row">
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Name</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort ">
              <span># Listings</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>CMP</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Floor</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Ceil</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Inc</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Spaceout</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Action</span>
            </th>
          </tr>
        </thead>
        <tbody
          style={{ display: "table-row-group" }}
          className="kt-datatable__body"
        >
          {isLoading && (
            <tr>
              <td style={{ width: "40vw", height: 75 }} colSpan={44}>
                <div style={{ position: "relative", left: "17vw", top: 0 }}>
                  <div className="kt-spinner kt-spinner--md kt-spinner--info"></div>
                </div>
              </td>
            </tr>
          )}

          {groups.map(group => {
            return (
              <GroupRow
                key={group.id}
                eventId={group.event_id}
                group={group}
                listings={listingsDictionnary[group.id]}
              />
            );
          })}
        </tbody>
      </table>

      <div style={{ margin: 25 }}>
        <button
          onClick={() => createGroup(eventId)}
          className="btn btn-success"
        >
          Create a Group
        </button>
      </div>
    </div>
  );
}
