import React from "react";

const RelatedListingsRow = ({ listing, idx }) => {

  const rowName = listing.row || (listing.products ? listing.products[0].row : "");

  return (
    <tr className="kt-datatable__row">
      <td style={{color: listing.sellerOwnInd ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">{listing.sellerOwnInd ? <i className="fa fa-circle"/> : idx}</td>
      <td style={{color: listing.sellerOwnInd ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">{listing.quantity}</td>
      <td style={{color: listing.sellerOwnInd ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">{listing.sectionName}</td>
      <td style={{color: listing.sellerOwnInd ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">{rowName}</td>
      <td style={{color: listing.sellerOwnInd ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">${listing.pricePerProduct.amount}</td>
      <td style={{color: listing.sellerOwnInd ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">{listing.attributes ? listing.attributes.toString() : "-"}</td>
    </tr>
  );
};

export default React.memo(RelatedListingsRow);
