import React from "react";

export const PublicNoteWarningIcon = ({ note }) => {
  if (!note) {
    return null;
  }
  const lowerCasedNote = note.toLowerCase();

  if (
    [
      "wait",
      "billets.ca",
      "do not sell",
      "do_not_touch",
      "virtuelle",
      "listed"
    ].some(keyword => lowerCasedNote.includes(keyword))
  ) {
    return (
      <i
        style={{ fontSize: 14, color: "rgb(251, 6, 6)" }}
        className="fa fa-exclamation-circle"
      />
    );
  }

  return null;
};
