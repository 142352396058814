import RelatedListingsRow from "./RelatedListingsRow";
import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import useInterval from "../../utils/use_interval";

export default function RelatedListingsList() {

  const filteredListings = useStoreState(state => {
    return state.stubhub_listings.filteredStubhubListings;
  });

  const selectedGroup = useStoreState(state => state.listings.selectedGroup);
  const reloadStubhubListingsThunk = useStoreActions(
    actions => actions.stubhub_listings.loadStubhubListingsThunk
  );
  const seatgeekId = useStoreState(state => state.events.seatgeekId);


  useInterval(() => reloadStubhubListingsThunk({ eventId: seatgeekId, reload: true }), 30000);

  return (
    (
      <div
        style={{
          height: `calc(100vh - ${!!selectedGroup ? 548 : 462}px)`,
          overflowY: "auto",
          marginBottom: 0
        }}
        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
      >
        <table
          className="kt-datatable__table"
          style={{ width: "100%", display: "table" }}
        >
          <thead
            className="kt-datatable__head"
            style={{ display: "table-header-group" }}
          >
          <tr className="kt-datatable__row">
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Rank</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Quantity</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Section</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Row</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Price</span>
            </th>
            <th className="kt-datatable__cell kt-datatable__cell--sort">
              <span>Att</span>
            </th>
          </tr>
          </thead>
          <tbody
            style={{ display: "table-row-group" }}
            className="kt-datatable__body"
          >
          {filteredListings.map((listing, idx) => (
            <RelatedListingsRow
              key={`${seatgeekId}-${listing.listingId}`}
              idx={idx + 1}
              listing={listing}
            />
          ))}
          </tbody>
        </table>
      </div>
    )
  )

}