import React from "react";

export default function Tab({ name, icon, value, activeTab, onSelect, isLinkActivated = true, isOver = false }) {
  return (
    <li className="nav-item">
      <button
        onClick={() => isLinkActivated ? onSelect(value) : () => {}}
        className={`nav-link${activeTab === value ? " active" : ""}`}
        data-toggle="tab"
        href="#"
        role="tab"
        style={{ flexDirection: isOver ? "column" : "row", justifyContent: "center", alignItems: "center",
            paddingBottom: isOver ? 0 : 10 }}
      >
          <i className={`fa fa-${icon}`} style={{ paddingBottom: isOver ? 5 : 0 }}></i>
          {name}
      </button>
    </li>
  );
}