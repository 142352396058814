import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { LISTING_TABLE_GROUPED_VIEW } from "../../../Store/LayoutStore";

export default function GroupedView() {
  const tableView = useStoreState(state => state.layout.listingTableView);
  const toggleGroupedView = useStoreActions(
    state => state.layout.toggleListingTableView
  );

  return (
    <button
      style={{ width: 50, backgroundColor: "#e1e3ec", borderColor: "#e1e3ec" }}
      type="button"
      className="btn btn-success btn-icon"
      onClick={toggleGroupedView}
    >
      <i
        style={{ fontSize: 16, color: "#959cb6" }}
        className={`fa fa-list${
          tableView === LISTING_TABLE_GROUPED_VIEW ? "" : "-alt"
        }`}
      ></i>
    </button>
  );
}
