import React from "react";

export default function AttributeButton({
  attributes,
  value,
  name,
  toggleAttributes
}) {
  let isActive = attributes.includes(value);

  return (
    <button
      style={{ padding: 2, height: 30 }}
      type="button"
      className={`btn btn-sm btn-${isActive ? "primary" : "secondary"}`}
      onClick={() => toggleAttributes(value.toString())}
    >
      {name}
    </button>
  );
}
