import React from "react";

export default function StubIcon({ internal_note }) {
  if (!internal_note) {
    return null;
  }

  if (!internal_note.includes("STUB") || internal_note.includes("-STUB")) {
    return null;
  }

  return (
    <span
      style={{
        color: "#111",
        display: "inline-flex",
        width: 18,
        height: 18,
        fontSize: "0.8rem",
        marginRight: 3
      }}
      className="kt-badge kt-badge--warning kt-badge--md"
    >
      S
    </span>
  );
}
