import React from "react";

export default function FilterButton({name, value, onDelete}) {
  return (
    <span
      style={{ marginRight: 5 }}
      className="btn btn-bold btn-sm btn-font-sm btn-label-success"
    >
      {name}: {value}
      <i
        style={{ cursor: "pointer", position: "relative", left: 12 }}
        className="fa fa-close"
        onClick={onDelete}
      />
    </span>
  );
}
