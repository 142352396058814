import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Text from "react-texty";
import "react-texty/styles.css";
import Numeral from "numeral";
import { BroadcastButton } from "./Buttons/BroadcastButton";
import { DeleteCriteriaButton } from "./Buttons/DeleteCriteriaButton";
import StubIcon from "./Icons/StubIcon";
import StockType from "./Icons/StockTypeIcon";
import { IncrementTypeIcon } from "./Icons/IncrementTypeIcon";
import { NotesIcon } from "./Icons/NotesIcon";
import { WarningSplitIcon } from "./Icons/WarningSplitIcon";
import { PublicNoteWarningIcon } from "./Icons/PublicNoteWarningIcon";
import { getHighlightColor, getSeats } from "./utils";

const ListingRow = React.memo(({ id, groups, onTabPress }) => {
  const selectListing = useStoreActions(
    actions => actions.listings.selectListing
  );
  const unselectListing = useStoreActions(
    actions => actions.listings.unselectListing
  );

  const multiselectListings = useStoreActions(
    actions => actions.listings.multiselectListings
  );

  const selectGroup = useStoreActions(actions => actions.groups.selectGroup);
  const unselectGroup = useStoreActions(
    actions => actions.groups.unselectGroup
  );

  const changeBroadcastType = useStoreActions(
    actions => actions.listings.changeBroadcastTypeThunk
  );
  const deleteCriteria = useStoreActions(
    actions => actions.pricing.deleteCriteriaThunk
  );
  const deleteCriteriaFromGroup = useStoreActions(
    actions => actions.groups.deleteListingFromGroupThunk
  );
  const changePriceOnSkybox = useStoreActions(
    actions => actions.listings.changePriceOnSkyboxThunk
  );

  const selectedListingId = useStoreState(
    state => state.listings.getSelectedListingId
  );
  const selectedGroupId = useStoreState(
    state => state.groups.getSelectedGroupId
  );

  const listing = useStoreState(state => state.listings.items[id]);
  const pricing = useStoreState(state => state.pricing.pricingByListingId(id));

  const isListingChecked = useStoreState(state =>
    state.listings.isListingChecked(id)
  );

  const uncheckListing = useStoreActions(
    actions => actions.listings.uncheckListing
  );
  const checkListing = useStoreActions(
    actions => actions.listings.checkListing
  );
  const groupId = useStoreState(state =>
    state.listings.getGroupIdByListingId(id)
  );


  const resetPrice = useStoreActions(actions => actions.listings.resetPrice);
  const setPrice = useStoreActions(actions => actions.listings.setCustomPrice);
  const customPrice = useStoreState(state =>
    state.listings.getCustomPriceById(id)
  );

  useEffect(() => {
    resetPrice([listing.id]);
  }, [listing.id, resetPrice]);

  if (!listing) {
    return null;
  }
  const price = customPrice || listing.price;

  const seats = getSeats(listing.seats);
  const highlightColor = getHighlightColor(pricing);


  return (
    <tr
      className="kt-datatable__row"
      style={{
        cursor: "pointer",
        border:
          (selectedListingId && selectedListingId === listing.id) ||
          (selectedGroupId && selectedGroupId === groupId)
            ? "dashed 2px #00a3ee"
            : 0,
        backgroundColor: highlightColor,
        userSelect: "none"
      }}
      onClick={evt => {
        evt.stopPropagation();
        if (evt.shiftKey) {
          if (!isListingChecked) {
            multiselectListings(listing.id);
            return;
          }
          uncheckListing(listing.id);
          return;
        }

        unselectGroup();
        unselectListing();
        if (groupId && groups[groupId]) {
          selectGroup(groups[groupId]);
          return;
        }
        selectListing(listing);
      }}
      key={listing.id}
    >
      <td colSpan={2} style={{ paddingLeft: 5 }} className="kt-datatable__cell">
        <label
          onClick={evt => evt.stopPropagation()}
          className="kt-checkbox kt-checkbox--single kt-checkbox--solid"
        >
          <input
            onChange={evt => {
              if (!isListingChecked) {
                checkListing(listing.id);
                return;
              }
              uncheckListing(listing.id);
            }}
            checked={isListingChecked}
            className={"listing-checkbox"}
            data-id={listing.id}
            type="checkbox"
          />
          &nbsp;
          <span></span>
        </label>
        <PublicNoteWarningIcon note={listing.public_note} />{" "}
        <WarningSplitIcon quantity={listing.quantity} pricing={pricing} />
      </td>
      <td style={{ width: 20, maxWidth: 20 }} className="kt-datatable__cell">
        {listing.quantity}
      </td>
      <td className="kt-datatable__cell">
        <Text>
          {listing.section.length <= 4
            ? listing.section
            : listing.section
                .toLowerCase()
                .split(" ")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
        </Text>
      </td>
      <td style={{ maxWidth: 50 }} className="kt-datatable__cell">
        <Text>
          {listing.row.length <= 4
            ? listing.row
            : listing.row
                .toLowerCase()
                .split(" ")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
        </Text>
      </td>
      <td style={{ maxWidth: 50 }} className="kt-datatable__cell">
        <Text>{seats.join("-")}</Text>
      </td>
      <td className="kt-datatable__cell">
        <span style={{ whiteSpace: "nowrap" }}>
          <StubIcon internal_note={listing.internal_note} />
          <StockType stockType={listing.stock_type} />
        </span>
      </td>
      <td style={{ whiteSpace: "wordwrap" }} className="kt-datatable__cell">
        {Numeral(listing.cost).format("$0,0.00")}
      </td>
      <td
        style={{
          whiteSpace: "wordwrap",
          padding: 0,
          width: "60px",
          position: "relative"
        }}
        className="kt-datatable__cell"
      >
        <input
          data-id={listing.id}
          onFocus={evt => evt.target.select()}
          onClick={evt => evt.stopPropagation()}
          onChange={evt => {
            setPrice({ id: listing.id, price: evt.target.value });
          }}
          onKeyDown={evt => {
            if (evt.keyCode === 9) {
              evt.preventDefault();
              onTabPress(listing.id);
            }
          }}
          onKeyPress={evt => {
            evt.stopPropagation();
            if (evt.key === "Enter") {
              changePriceOnSkybox([{ id: listing.id, price }]);
            }
          }}
          value={price}
          style={{ color: "#1f2024", width: "60px", fontWeight: 700 }}
          className={"price-inline-update"}
        />
        {customPrice && (
          <span className="form-control-feedback form-control-feedback-listprice form-control-feedback-listprice-pending">
            <i
              className="fa fa-exclamation-triangle"
              style={{
                display: "block",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                color: "orange",
                fontSize: 16,
                left: 0,
                pointerEvents: "none"
              }}
            ></i>
          </span>
        )}
      </td>

      <td style={{ maxWidth: 40, textAlign: "right" }} className="kt-datatable__cell">
        <Text>{listing.days_old}</Text>
      </td>

      <td style={{ whiteSpace: "wordwrap" }} className="kt-datatable__cell">
        {pricing ? `${Numeral(pricing.cmp).format("$0,0.00")}` : "N/A"}
      </td>

      <td
        style={{ whiteSpace: "wordwrap" }}
        className="kt-datatable__cell pricing-cell"
      >
        {pricing ? `${Numeral(pricing.floor).format("$0,0.00")}` : "N/A"}
      </td>

          <td style={{ whiteSpace: "wordwrap" }} className="kt-datatable__cell">
        {pricing ? `${Numeral(pricing.ceiling).format("$0,0.00")}` : "N/A"}
      </td>
      <td className="kt-datatable__cell pricing-cell">
        {pricing && (
          <IncrementTypeIcon
            amount={pricing.increment_amount}
            value={pricing.increment_type}
          />
        )}
        {!pricing && "N/A"}
      </td>

      <td
        style={{ padding: 0, whiteSpace: "nowrap" }}
        className="kt-datatable__cell"
      >
        <NotesIcon
          public_note={listing.public_note}
          internal_note={listing.internal_note}
          id={listing.id}
        />

        <BroadcastButton
          onAction={changeBroadcastType}
          id={listing.id}
          isBroadcast={listing.is_broadcast}
        />
        {pricing && (
          <DeleteCriteriaButton
            onAction={() => {
              if (pricing.listing_id) {
                deleteCriteria([listing.id]);
              } else {
                deleteCriteriaFromGroup([listing.id]);
              }
            }}
          />
        )}
      </td>
    </tr>
  );
});

export default ListingRow;
