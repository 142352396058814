import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

import defaultUserIcon from "../../assets/images/default-user.jpg";

export const Note = ({ note }) => {
  const updateNote = useStoreActions(actions => actions.events.updateNoteThunk);
  const deleteNote = useStoreActions(actions => actions.events.deleteNoteThunk);
  const togglePin = useStoreActions(actions => actions.events.togglePinThunk);

  const [noteInput, setNoteInput] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const user = useStoreState(state => state.auth.user);

  useEffect(() => {
    setNoteInput(note.note);
  }, [note]);

  return (
    <div key={note.created} className="kt-notes__item">
      <div className="kt-notes__media">
        <span style={{ width: "auto" }} className="kt-notes__icon">
            <img alt={note.user_fullname} src={note.user_avatar ? note.user_avatar : defaultUserIcon} />

          <i className="flaticon2-line-chart kt-font-success"></i>
        </span>
      </div>
      <div className="kt-notes__content">
        <div className="kt-notes__section">
          <div className="kt-notes__info">
            <i
              style={{
                color: note.pinned ? "#1dc9b7" : "inherit",
                marginRight: 4,
                fontSize: 16
              }}
              className="fa fa-thumb-tack"
              onDoubleClick={e => {
                togglePin({ id: note.id, pinned: note.pinned === 0 ? 1 : 0 });
              }}
            />
            <span className="kt-notes__title"> {note.user_fullname}</span>
            <span
              title={moment(note.created).format("ddd MMM Do, h:mm A")}
              className="kt-notes__desc"
            >
              {moment(note.created).format("ddd MMM Do, h:mm A")}
            </span>
          </div>
          <div
            style={{
              float: "right",
              fontSize: 18,
              cursor: "pointer",
              position: "relative",
              right: 5,
              top: 5
            }}
          >
            <i
              onDoubleClick={e => {
                deleteNote(note.id);
              }}
              className="fa fa-trash trash-icon"
            />
          </div>
        </div>
        <span className="kt-notes__body">
          {user.sub === note.user_id && isEditing && (
              <input
                onChange={e => {
                  setNoteInput(e.target.value);
                }}
                onDoubleClick={() => setIsEditing(!isEditing)}
                onKeyPress={e => {
                  if (noteInput.length > 1 && e.key === "Enter") {
                    updateNote({ id: note.id, note: noteInput });
                    e.target.blur();
                  }
                }}
                value={noteInput}
                className={"note-inline-update"}
              />
          )}
          {((user.sub !== note.user_id) || (user.sub === note.user_id && !isEditing)) && <div onDoubleClick={() => setIsEditing(!isEditing)}>{note.note}</div>}
        </span>
      </div>
    </div>
  );
};
