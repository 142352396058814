import React from "react";

export default function DeliveryTypeButton({
  name,
  delivery_types,
  toggleDeliveryType,
  value
}) {
  let isActive = delivery_types.includes(value.toString());

  return (
    <button
      style={{ padding: 2, height: 30 }}
      type="button"
      className={`btn btn-sm btn-${isActive ? "primary" : "secondary"}`}
      onClick={() => toggleDeliveryType(value)}
    >
      {name}
    </button>
  );
}
