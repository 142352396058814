import React from "react";
import ListingsList from "../Listings/ListingsList";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import Tab from "../Utils/Tab";
import { GROUP_VIEW, LISTING_VIEW } from "../../Store/LayoutStore";
import { SelectedGroupView } from "../Groups/SelectedGroupView";
import GroupList from "../Groups/GroupList";
import Texty from "react-texty";
import Numeral from "numeral";

export const LISTING_VIEW_WIDTH = "calc(100vw - 750px - 556px - 125px)";

export default function ListingView() {
  const listingView = useStoreState(state => state.layout.listingView);
  const changeListingViewTab = useStoreActions(
    actions => actions.layout.changeListingViewThunk
  );
  const event = useStoreState(state => state.events.selectedEvent);
  const currentEventLoading = useStoreState(
    state => state.events.currentEventLoading
  );
  const customPrices = useStoreState(state => state.listings.customPrices);
  const resetAllPrices = useStoreActions(
    state => state.listings.resetAllPrices
  );
  const changePriceOnSkybox = useStoreActions(
    state => state.listings.changePriceOnSkybox
  );

  const meteo = useStoreState(state => state.events.meteo);
  const lowestPrice = useStoreState(state => state.stubhub_listings.lowestPrice);
  const onsaleDate = useStoreState(state => state.events.onsaleDate);

  const skyboxData = useStoreState(state => state.events.skyboxData);
  let yes = "";
  let today = "";
  let last7 = "";
  let last30 = "";
  if (skyboxData) {
    yes = skyboxData.yesterday;
    today = skyboxData.today;
    last7 = skyboxData.last7;
    last30 = skyboxData.last30;
  }

  const selectedGroup = useStoreState(state => state.groups.selectedGroup);

  if (selectedGroup && listingView === GROUP_VIEW) {
    return <SelectedGroupView />;
  }

  if (!event && !currentEventLoading) {
    return (
      <div
        style={{
          fontSize: 10,
          marginLeft: 20,
          marginTop: 10,
          width: LISTING_VIEW_WIDTH,
          minWidth: 800
        }}
      >
        <div
          style={{ height: "calc(100vh - 30px)", overflow: "auto" }}
          className="kt-portlet kt-portlet--mobile"
        >
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-line-chart"></i>
              </span>
              <h3 className="kt-portlet__head-title">No event selected</h3>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit"></div>
        </div>
      </div>
    );
  }

  const eventDate = moment.tz(
    event ? event.event_date : currentEventLoading.event_date,
    "utc"
  );

  const seatgeekUrl = event ? event.seatgeek_url : currentEventLoading.seatgeek_url;
  const dateString = `${eventDate.format(
    "ddd MMM Do YYYY"
  )} at ${eventDate.format("h:mm A")}`;

  const name = event ? event.name : currentEventLoading.name;
  const venueInfo = event ? `${event.venue_name} - ${event.venue_city}` :`${currentEventLoading.venue_name} - ${currentEventLoading.venue_city}`;
  const eventListingQty = event ? event.quantity : currentEventLoading.quantity;
  const eventListingCost = Numeral(
    event ? event.cost : currentEventLoading.cost
  ).format("$0,0.00");

  const hasAPriceChange = Object.keys(customPrices).some(
    key => !!customPrices[key]
  );

  return (
    <div
      style={{
        fontSize: 10,
        marginLeft: 20,
        marginTop: 10,
        width: LISTING_VIEW_WIDTH,
        minWidth: 900
      }}
    >
      {hasAPriceChange && (
        <div id="bulkActions">
          <div
            style={{
              background: "#ffb822",
              color: "#fff",
              padding: "5px 10px 5px 15px",
              display: "flow-root"
            }}
          >
            <div style={{ marginRight: 20 }}>
              <h3
                id="check-unsaved-prices-label"
                style={{
                  margin: "0 20px 0 0",
                  display: "inline-block",
                  paddingTop: 5,
                  fontSize: 24,
                  float: "left"
                }}
              >
                You have unsaved pricing updates
              </h3>
            </div>

            <div style={{ marginBottom: 5, marginTop: 5, float: "right" }}>
              <button
                onClick={_ => resetAllPrices()}
                style={{ marginRight: 5 }}
                className="btn btn-google btn-sm"
              >
                Discard
              </button>
              <button
                onClick={_ =>
                  changePriceOnSkybox(
                    Object.keys(customPrices).map(key => {
                      return { id: key, price: customPrices[key] };
                    })
                  )
                }
                className="btn btn-brand btn-sm"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}

      <div
          style={{height: "calc(100vh - 30px)", overflow: "auto", paddingTop: 5}}
          className="kt-portlet kt-portlet--tabs"
      >
        <div className="kt-portlet__head kt-portlet__head--lg" style={{paddingBottom: 3, alignItems: "center"}}>

              <h5 style={{paddingRight: 10, borderRight: '1px solid #ebedf2'}}>
                  <Texty
                      tagName={"a"}
                      style={{color: "#464457", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: 300}}
                      href={seatgeekUrl}
                      target={"_blank"}
                  >
                    {name}
                  </Texty>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Texty style={{fontWeight: 'bold', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: 200, fontSize: 12}}>
                    | {venueInfo}
                  </Texty>
                </div>

                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <small style={{padding: 0}}>
                    {dateString} | DTE:{' '}
                    <span className="kt-font-brand">
                {eventDate.diff(moment(), 'days')}
              </span>
                  </small>
                </div>
              </h5>

              <div style={{paddingLeft: 10, fontSize: 14}}>
                <small style={{textAlign: 'center', display: 'flex', fontWeight: 500, whiteSpace: 'nowrap' }}>
                  <span style={{marginRight: 5, paddingRight: 5, borderRight: '1px solid #ebedf2'}}>
                    <span className="kt-font-brand">{eventListingQty}</span> @
                    <span className="kt-font-brand">{eventListingCost}</span>{" "}
                  </span>
                        <span style={{marginRight: 10}}>
                    Today: <span className="kt-font-brand">{today}</span>{" "}
                  </span>
                        <span style={{marginRight: 10}}>
                    Yest: <span className="kt-font-brand">{yes}</span>{" "}
                  </span>
                        <span style={{marginRight: 10}}>
                    Last7: <span className="kt-font-brand">{last7}</span>{" "}
                  </span>
                        <span style={{marginRight: 50}}>
                    Last30: <span className="kt-font-brand">{last30}</span>{" "}
                  </span>
                  </small>
                  <small style={{ padding: 0, fontWeight: 500}}>
                  <span style={{marginRight: 10}}>
                    Get IN: <span className="kt-font-brand">
                    {lowestPrice? Numeral(lowestPrice).format("$0,0") : 'N/A'}
                  </span>
                  </span>
                    <span style={{marginRight: 10}}>
                    Onsale Date: <span className="kt-font-brand">{onsaleDate}</span>
                  </span>
                    <span style={{marginRight: 10}}>
                      Meteo: <span className="kt-font-brand">
                      {moment(eventDate).diff(moment(), 'days') <= 5 ? (meteo?.description ? meteo?.description : 'N/A') : 'N/A'}
                      </span>
                        <div style={{display: 'inline-block', width: 0, height: 0}}>
                          {meteo?.icon && (
                            <img src={'https://openweathermap.org/img/wn/' + meteo?.icon + '.png'} alt={meteo?.description} width={30} height={30} style={{ position: 'relative', top: -1, left: -3 }} />
                          )}
                        </div>
                  </span>
                </small>
              </div>

          <div style={{ minWidth: 120, height: '100%' }} className="kt-portlet__head-toolbar">
            <ul
              className="nav nav-tabs nav-tabs-bold nav-tabs-line nav-tabs-line-right nav-tabs-line-brand"
              role="tablist"
            >
              <Tab
                name={"Listings"}
                value={LISTING_VIEW}
                icon={"ticket"}
                activeTab={listingView}
                onSelect={changeListingViewTab}
                isLinkActivated
                isOver
              />

              <Tab
                name={"Groups"}
                value={GROUP_VIEW}
                icon={"users"}
                activeTab={listingView}
                onSelect={changeListingViewTab}
                isLinkActivated
                isOver
              />
            </ul>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {listingView === LISTING_VIEW && <ListingsList />}
          {listingView === GROUP_VIEW && <GroupList />}
        </div>
      </div>
    </div>
  );
}
