import React, { useEffect, useState } from "react";
import { LISTING_VIEW_WIDTH } from "../Layout/ListingView";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ListingRowInsideGroup } from "./ListingRowInsideGroup";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ColumnRow from "../Listings/ColumnRow";

export const SelectedGroupView = () => {
  const selectedGroup = useStoreState(state => state.groups.selectedGroup);
  const unselectGroup = useStoreActions(
    actions => actions.groups.unselectGroup
  );

  const reorderFct = useStoreActions(
    actions => actions.groups.reorderListingsInGroups
  );
  const removeListingInroupFct = useStoreActions(
    actions => actions.groups.removeListingInGroup
  );

  const [dragging, setToDragging] = useState("");

  const [pristine, changePristine] = useState(true);

  const sortInGroupsFct = useStoreActions(
    actions => actions.groups.sortInGroupsThunk
  );

  useEffect(() => {
    changePristine(true);
  }, []);

  const reorder = args => {
    changePristine(false);
    reorderFct(args);
  };

  const sortInGroups = args => {
    changePristine(false);
    sortInGroupsFct(args);
  };

  const removeListingInGroup = args => {
    changePristine(false);
    removeListingInroupFct(args);
  };
  const listings = selectedGroup.listings;

  const [{ sortField, sortOrder }, setSort] = useState({
    sortField: null,
    sortOrder: "ASC"
  });

  const sortFunc = field => {
    if (field === sortField) {
      if (sortOrder === "DESC") {
        setSort({ sortField, sortOrder: "ASC" });
        sortInGroups({ sortField, sortOrder: "ASC" });
        return;
      }
      setSort({ sortField, sortOrder: "DESC" });
      sortInGroups({ sortField, sortOrder: "DESC" });
      return;
    }
    setSort({ sortField: field, sortOrder: "ASC" });
    sortInGroups({ sortField: field, sortOrder: "ASC" });
  };

  return (
    <div
      style={{
        fontSize: 10,
        marginLeft: 20,
        marginTop: 10,
        width: LISTING_VIEW_WIDTH
      }}
    >
      <div
        style={{
          height: "calc(100vh - 30px)",
          overflow: "auto",
          border: pristine ? "none" : "solid 5px #1dc9b7"
        }}
        className="kt-portlet kt-portlet--mobile"
      >
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div style={{ width: "100%" }} className="kt-portlet__head-label">
            <h3
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between"
              }}
              className="kt-portlet__head-title"
            >
              <div>
                Listing in group <strong>{selectedGroup.name}</strong>
              </div>
              <div>
                <button onClick={unselectGroup} style={{ border: "none" }}>
                  <i style={{ fontSize: 24 }} className="fa fa-window-close" />
                </button>
              </div>
            </h3>
          </div>
        </div>

        <div
          style={{ height: "calc(100vh - 30px)", overflow: "auto" }}
          className="kt-portlet__body kt-portlet__body--fit"
        >
          <div
            style={{ marginBottom: 0 }}
            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
          >
            <table
              className="kt-datatable__table"
              width="100%"
              style={{ display: "table", tableLayout: "fixed" }}
            >
              <thead
                style={{ display: "table-header-group" }}
                className="kt-datatable__head"
              >
                <tr className="kt-datatable__row">
                  <th className="kt-datatable__cell kt-datatable__cell--sort">
                    <span>Rank</span>
                  </th>

                  <ColumnRow
                    style={{ justifyContent: "normal" }}
                    name={"Qty"}
                    value={"quantity"}
                    sortField={sortField}
                    sortFunc={sortFunc}
                    sortOrder={sortOrder}
                  />

                  <ColumnRow
                    style={{ justifyContent: "normal" }}
                    name={"Section"}
                    value={"section"}
                    sortField={sortField}
                    sortFunc={sortFunc}
                    sortOrder={sortOrder}
                  />
                  <ColumnRow
                    style={{ justifyContent: "normal" }}
                    name={"Row"}
                    value={"row"}
                    sortField={sortField}
                    sortFunc={sortFunc}
                    sortOrder={sortOrder}
                  />

                  <th className="kt-datatable__cell kt-datatable__cell--sort">
                    <span>Seat</span>
                  </th>
                  <th className="kt-datatable__cell kt-datatable__cell--sort">
                    <span>Type</span>
                  </th>
                  <ColumnRow
                    style={{ justifyContent: "normal" }}
                    name={"Cost"}
                    value={"cost"}
                    sortField={sortField}
                    sortFunc={sortFunc}
                    sortOrder={sortOrder}
                  />
                  <ColumnRow
                    style={{ justifyContent: "normal" }}
                    name={"Price"}
                    value={"price"}
                    sortField={sortField}
                    sortFunc={sortFunc}
                    sortOrder={sortOrder}
                  />
                  <th className="kt-datatable__cell kt-datatable__cell--sort">
                    <span>CP</span>
                  </th>
                  <th className="kt-datatable__cell kt-datatable__cell--sort">
                    <span>Action</span>
                  </th>
                </tr>
              </thead>

              <DragDropContext
                onDragStart={result => {
                  setToDragging(result.draggableId);
                }}
                onDragEnd={result => {
                  setToDragging("");

                  if (!result.destination) {
                    return;
                  }

                  reorder({
                    startIndex: result.source.index,
                    endIndex: result.destination.index
                  });
                }}
              >
                <Droppable droppableId={`group-droppable-${selectedGroup.id}`}>
                  {provided => (
                    <tbody
                      ref={provided.innerRef}
                      style={{ display: "table-row-group" }}
                      className="kt-datatable__body"
                      {...provided.droppableProps}
                    >
                      {listings.map((listing, idx) => (
                        <ListingRowInsideGroup
                          index={idx}
                          key={listing.listing_id}
                          id={listing.listing_id}
                          dragging={dragging}
                          spaceout_type={selectedGroup.spaceout_type}
                          spaceout_amount={selectedGroup.spaceout_amount}
                          removeListingInGroup={removeListingInGroup}
                        />
                      ))}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
