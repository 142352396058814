import React from "react";

export const DeleteCriteriaButton = function({ onAction, style = {} }) {
  return (
    <i
      onClick={evt => evt.stopPropagation()}
      onDoubleClick={onAction}
      style={{ fontSize: 18, marginLeft: 4, cursor: "pointer", ...style }}
      className={"fa fa-trash trash-icon"}
    />
  );
};
