import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import Map from "./Map";
import MapTooltip from "./MapTooltip";
import ErrorBoundary from "../Utils/ErrorBoundary";
import {
  NOTE_FULL_HEIGHT,
  NOTE_FULL_VIEW,
  NOTE_SMALL_HEIGHT
} from "../../Store/LayoutStore";

const MapSelection = () => {
  const selectedEvent = useStoreState(state => state.events.selectedEvent);
  const isLoadingEvent = useStoreState(state => state.events.loading);
  const noteView = useStoreState(state => state.layout.noteView);

  const [tooltip, setTooltip] = useState();

  if (!isLoadingEvent && !selectedEvent) {
    return (
      <div
        style={{
          marginTop: 10,
          height:
            noteView === NOTE_FULL_VIEW ? NOTE_FULL_HEIGHT : NOTE_SMALL_HEIGHT
        }}
        className="kt-portlet"
      >
        <div
          style={{
            margin: "auto",
            fontSize: 28,
            fontWeight: 400
          }}
          className="kt-portlet__body"
        >
          <span>
            <i className="fa fa-ban" /> No event Selected
          </span>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div style={{ marginTop: 10 }} className="kt-portlet">
        <div style={{ padding: 0 }} className="kt-portlet__body">
          <ErrorBoundary>
            <Map setTooltip={setTooltip} />
          </ErrorBoundary>
        </div>
      </div>

      <MapTooltip tooltip={tooltip} />
    </div>
  );
};

export default React.memo(MapSelection);
