import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { LISTING_DETAILED_VIEW } from "../../../Store/LayoutStore";

export default function SimpleDetailView() {
  const simpleView = useStoreState(
    state => state.layout.listingSimpleDetailedView
  );
  const toggleSimpleDetailedView = useStoreActions(
    state => state.layout.toggleSimpleDetailedView
  );

  return (
    <button
      style={{ width: 50, backgroundColor: "#e1e3ec", borderColor: "#e1e3ec" }}
      type="button"
      className="btn btn-icon"
      onClick={toggleSimpleDetailedView}
    >
      <i
        style={{ fontSize: 18, color: "#959cb6" }}
        className={`fa fa-eye${
          simpleView === LISTING_DETAILED_VIEW ? "" : "-slash"
        }`}
      ></i>
    </button>
  );
}
