import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Draggable } from "react-beautiful-dnd";
import StubIcon from "../Listings/Icons/StubIcon";
import StockType from "../Listings/Icons/StockTypeIcon";
import { NotesIcon } from "../Listings/Icons/NotesIcon";
import { BroadcastButton } from "../Listings/Buttons/BroadcastButton";

export const ListingRowInsideGroup = ({
  index,
  id,
  dragging,
  spaceout_amount,
  spaceout_type,
  removeListingInGroup
}) => {
  const listing = useStoreState(state => state.listings.items[id]);
  const criteriaPrice = useStoreState(state => state.pricing.criteriaPrice);
  const changeBroadcastType = useStoreActions(
    actions => actions.listings.changeBroadcastTypeThunk
  );
  if (!listing) {
    return null;
  }

  let cp = criteriaPrice + index * spaceout_amount;
  if (spaceout_type === 1) {
    cp = criteriaPrice + index * ((spaceout_amount / 100) * criteriaPrice);
  }

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided, snapshot) => {
        return (
          <tr
            className="kt-datatable__row"
            onClick={() => {}}
            key={listing.id}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              ...provided.dragHandleProps.style,
              display: dragging === id.toString() ? "table" : "table-row",
              tableLayout: dragging === id.toString() ? "fixed" : "initial"
            }}
            ref={provided.innerRef}
          >
            <td className="kt-datatable__cell">
              <span>{index + 1}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>{listing.quantity}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>{listing.section}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>{listing.row}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>{listing.seats}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>
                <StubIcon internal_note={listing.internal_note} />{" "}
                <StockType stockType={listing.stock_type} />{" "}
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span>${parseFloat(listing.cost).toFixed(2)}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>${parseFloat(listing.price).toFixed(2)}</span>
            </td>
            <td className="kt-datatable__cell">
              <span>${parseFloat(cp).toFixed(2)}</span>
            </td>
            <td className="kt-datatable__cell">
              <NotesIcon
                public_note={listing.public_note}
                internal_note={listing.internal_note}
              />

              <BroadcastButton
                onAction={changeBroadcastType}
                id={listing.id}
                isBroadcast={listing.is_broadcast}
              />
                <i
                  style={{ cursor: "pointer", fontSize:18,paddingLeft:4 }}
                  onClick={() => {
                    removeListingInGroup(listing.id);
                  }}
                  className="fa fa-arrow-down"
                />{" "}
            </td>
          </tr>
        );
      }}
    </Draggable>
  );
};
