import { LayoutStoreModel } from "../types";
import { action, actionOn, thunk } from "easy-peasy";

export const LISTING_VIEW = "LISTING_VIEW";
export const GROUP_VIEW = "GROUP_VIEW";

export const LISTING_TABLE_GROUPED_VIEW = "LISTING_TABLE_GROUPED_VIEW";
export const LISTING_TABLE_UNGROUPED_VIEW = "LISTING_TABLE_UNGROUPED_VIEW";

export const LISTING_SIMPLE_VIEW = "LISTING_SIMPLE_VIEW";
export const LISTING_DETAILED_VIEW = "LISTING_DETAILED_VIEW";

export const NOTE_FULL_VIEW = "NOTE_FULL_VIEW";
export const NOTE_SMALL_VIEW = "NOTE_SMALL_VIEW";

export const NOTE_FULL_HEIGHT = 556;
export const NOTE_SMALL_HEIGHT = 336;

export const MAP_FULL_HEIGHT = 300;

export const SALES_TABS = "SALES_TABS";
export const EVENT_NOTES_TABS = "EVENT_NOTES_TABS";
export const SLY_GRAPHS_TABS = "SLY_GRAPHS_TABS";
export const INVO_TABS = "INVO_TABS";
export const OWN_SALES_TABS = "OWN_SALES_TABS";
export const ACTIVE_INVO_TABS = "ACTIVE_INVO_TABS";
export const GET_INS_TABS = "GET_INS_TABS";

export const PRICING_VIEW = "PRICING_VIEW"
export const SKYBOX_VIEW = "SKYBOX_VIEW"

export const LayoutStore: LayoutStoreModel = {
  activeTab: EVENT_NOTES_TABS,
  rightLayoutView: PRICING_VIEW,
  changeTab: action((state, payload) => {
    state.activeTab = payload;
  }),
  sidebarOpen: true,
  toggleSidebar: action((state, payload) => {
    state.sidebarOpen = !state.sidebarOpen;
  }),
  openSidebar: action((state, payload) => {
    state.sidebarOpen = true;
  }),
  noteView: NOTE_FULL_VIEW,
  listingView: LISTING_VIEW,
  listingTableView: LISTING_TABLE_GROUPED_VIEW,
  listingSimpleDetailedView: LISTING_DETAILED_VIEW,
  changeListingViewThunk: thunk((actions, payload) => {
    actions.changeListingView(payload);
  }),
  onLoadEvent: actionOn(
    (actions, store) => store.events.loadEventDataThunk.startType,
    state => {
      state.sidebarOpen = false;
      state.listingView = LISTING_VIEW;
    }
  ),
  onSelectListing: actionOn(
    (actions, store) => store.listings.selectListing,
    (state, target) => {
      state.rightLayoutView = PRICING_VIEW;
    }
  ),
  onSelectGroup: actionOn(
    (actions, store) => store.groups.selectGroup,
    (state, target) => {
      state.rightLayoutView = PRICING_VIEW;
    }
  ),
  changeToPricingView: action((state) => {
    state.rightLayoutView = PRICING_VIEW;
  }),
  changeToSkyboxView: action((state) => {
    state.rightLayoutView = SKYBOX_VIEW;
  }),
  changeListingView: action((state, payload) => {
    state.listingView = payload;
    state.rightLayoutView = PRICING_VIEW;
  }),

  toggleListingTableView: action((state, payload) => {
    state.listingTableView =
      state.listingTableView === LISTING_TABLE_UNGROUPED_VIEW
        ? LISTING_TABLE_GROUPED_VIEW
        : LISTING_TABLE_UNGROUPED_VIEW;
  }),

  toggleSimpleDetailedView: action((state, payload) => {
    state.listingSimpleDetailedView =
      state.listingSimpleDetailedView === LISTING_SIMPLE_VIEW
        ? LISTING_DETAILED_VIEW
        : LISTING_SIMPLE_VIEW;
  }),
  toggleNoteView: action((state, payload) => {
    state.noteView =
      state.noteView === NOTE_FULL_VIEW
        ? NOTE_SMALL_VIEW
        : NOTE_FULL_VIEW;
  }),

  setToDefaultSimpleDetailedView: action((state, payload) => {
    state.listingSimpleDetailedView = LISTING_DETAILED_VIEW;
  }),
  setToDefaultListingTableView: action((state, payload) => {
    state.listingTableView = LISTING_TABLE_GROUPED_VIEW;
  })
};
