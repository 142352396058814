import React from "react";
import Pricing from "../Pricings/Pricing";
import RelatedListings from "../RelatedListings/RelatedListings";
import Skybox from "../Skybox/Skybox";
import { useStoreState } from "easy-peasy";
import { PRICING_VIEW, SKYBOX_VIEW } from "../../Store/LayoutStore";


export default function PricingViewLayout() {

  const layoutView = useStoreState(state => state.layout.rightLayoutView);

  return (
    <div style={{ marginLeft: 20, width: "440px" }}>
      {layoutView === SKYBOX_VIEW && <Skybox />}
      {layoutView === PRICING_VIEW && <><Pricing /> <RelatedListings /></>}
    </div>
  )

}