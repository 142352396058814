import React from "react";
import moment from "moment";

function OwnSalesRow({sale}) {

    const formatSales = sale => {
        return moment(sale.invoiceDate).format("MMM DD YYYY hh:mm A");
        };

  return (
    <tr className="kt-datatable__row">
      <td className="kt-datatable__cell">{formatSales(sale)}</td>
      <td className="kt-datatable__cell" style={{ textAlign: 'right' }}>{sale.quantity}</td>
      <td className="kt-datatable__cell" style={{ textAlign: 'right' }}>{sale.section}</td>
      <td className="kt-datatable__cell" style={{ textAlign: 'right' }}>{sale.row}</td>
      <td className="kt-datatable__cell" style={{ textAlign: 'right' }}>{sale.profitMargin}</td>
      <td className="kt-datatable__cell" style={{ textAlign: 'right' }}>{sale.total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
      </td>
    </tr>
  )
}

export default OwnSalesRow;