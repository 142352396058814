import { createStore } from "easy-peasy";
import {
  StoreModel
} from "./types/index";
import { AuthStore } from "./Store/AuthStore";
import { SaleStore } from "./Store/SaleStore";
import { ListingStore } from "./Store/ListingStore";
import { EventStore } from "./Store/EventStore";
import { LayoutStore } from "./Store/LayoutStore";
import { PricingStore } from "./Store/PricingStore";
import { StubhubListing } from "./Store/StubhubListing";
import { GroupStore } from "./Store/GroupStore";
import { SerenaStore } from "./Store/SerenaStore";


const model: StoreModel = {
  layout: LayoutStore,
  auth: AuthStore,
  sales: SaleStore,
  events: EventStore,
  listings: ListingStore,
  pricing: PricingStore,
  stubhub_listings: StubhubListing,
  groups: GroupStore,
  //serena: SerenaStore,
};

const store = createStore(model);

export default store;
