import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "easy-peasy";
import store from "./Store";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("imngox/kobe-frontend");
  setupLogRocketReact(LogRocket);
  Sentry.init({
    dsn: "https://9241d6d9f196410998198655e7ed310e@sentry.io/1831014"
  });

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

const AppWithProvider = () => {
  return (
    <StoreProvider store={store}>
     <App />
    </StoreProvider>
  );
};

ReactDOM.render(<AppWithProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
