import React from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {buildDictionary} from "@jfortin/kobe-criteria"

export default React.memo(function CustomMapping({ setIsMapping }) {

    const sectionDictionary = useStoreState(state => state.events.sectionMapping)
    const allSectionsTM = useStoreState(state => state.events.mapSectionsName);
    const allSgSections = useStoreState(state => state.stubhub_listings.allSgSections);
    const selectedEvent = useStoreState(state => state.events.selectedEvent);

    const sortedTm = allSectionsTM.sort((a, b) => a > b ? 1 : -1);
    const sortedSG = allSgSections.sort((a, b) => a > b ? 1 : -1);
    const saveDic = useStoreActions(actions => actions.events.saveMappingSectionThunk);
    const customDic = buildDictionary(allSectionsTM, allSgSections, sectionDictionary);

    return <div style={{marginTop: 10}} className="kt-portlet kt-portlet--tab">
        <div className="kt-portlet__head">
            <div style={{width: "100%", justifyContent: "space-between"}} className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">

                    <i
                        onClick={(evt) => {
                            setIsMapping(false)
                            evt.stopPropagation()
                        }}
                        style={{fontSize: 16, marginRight: 9, cursor: "pointer"}}
                        className={"fa fa-arrow-left"}
                    />




                    Custom Mapping</h3>
            </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
            <div
                style={{
                    height: `calc(70vh)`,
                    overflowY: "auto",
                    marginBottom: 0
                }}
                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
            >
                <table
                    className="kt-datatable__table"
                    style={{width: "100%", display: "table"}}
                >
                    <thead
                        className="kt-datatable__head"
                        style={{display: "table-header-group"}}
                    >
                    <tr className="kt-datatable__row">
                        <th className="kt-datatable__cell kt-datatable__cell--sort">
                            <span>Geek Section</span>
                        </th>
                        <th className="kt-datatable__cell kt-datatable__cell--sort">
                            <span>TM Section</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody
                        style={{display: "table-row-group"}}
                        className="kt-datatable__body"
                    >

                    {sortedSG.map(((sgSection, idx) => {
                        const isInDic = !!sectionDictionary[sgSection];

                        const value = sectionDictionary[sgSection] ?? "AUTO";


                        return (<tr key={`sg-section-${sgSection}`} className="kt-datatable__row">
                                <td style={{color: isInDic ? '#1dc9b7' : 'inherit'}}
                                    className="kt-datatable__cell">{sgSection}</td>
                                <td style={{color: isInDic ? '#1dc9b7' : 'inherit'}} className="kt-datatable__cell">

                                    <select value={value} onChange={(evt) => {
                                        const dicCopy = {...sectionDictionary};

                                        if (evt.target.value == "AUTO") {
                                            delete dicCopy[sgSection];
                                        } else {
                                            dicCopy[sgSection] = evt.target.value;
                                        }
                                        saveDic({dic: dicCopy, eventId: selectedEvent.id});
                                    }}>
                                        <option key={`sg-section-${sgSection}-auto`}>AUTO</option>
                                        <option key={`sg-section-${sgSection}-dontmap`}>DONT MAP</option>
                                        {sortedTm.map(tmSection => {
                                            return (
                                                <option
                                                    key={`sg-section-${sgSection}-${tmSection}`}>{tmSection}</option>
                                            )
                                        })}


                                    </select>


                                </td>
                            </tr>
                        )
                    }))}

                    </tbody>
                </table>
            </div>


        </div>
    </div>


});