import React from "react";

export default function IncrementPlusMinusButton({ value, changeValue }) {
  return (
    <button
      onClick={evt => {
        changeValue(value >= 0 ? -1 : 1);
      }}
      style={{ height: 32, width: 34 }}
      className={`btn btn-${value >= 0 ? "success" : "google"}`}
      type="button"
    >
      {value >= 0 ? "+" : "-"}
    </button>
  );
}
