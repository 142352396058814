import React, { useState } from "react";
import moment from "moment";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function () {
  const switchToPricingView = useStoreActions(
    (actions) => actions.layout.changeToPricingView
  );

  const lastClickedSectionId = useStoreState(
    (state) => state.pricing.lastClickedMapSection
  );
  const sectionData = useStoreState((state) =>
    state.events.getSection(lastClickedSectionId)
  );

  const event = useStoreState((state) => state.events.selectedEvent);
  const submitPO = useStoreActions((actions) => actions.pricing.poOnSkybox);

  const loadListingsThunk = useStoreActions(
    (actions) => actions.listings.loadListingsThunk
  );

  const onSubmit = async () => {
    try {
      setCreating(true);
      changeSplitType("");
      changeStockType("ELECTRONIC");
      changeHideSeatNumbers(true);
      changeInternalNote("");
      changePublicNote("");
      changeHighSeat(0);
      changeLowSeat(0);
      changeRow("");
      await submitPO({
        row,
        quantity,
        lowSeat,
        highSeat,
        section,
        eventId: event.id,
        inHandDate: moment(event.event_date)
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
        stockType,
        splitType,
        internalNote,
        publicNote,
        hideSeatNumbers
      });
      setCreating(false);

      await loadListingsThunk({ eventId: event.id, reload: true });
    } catch (err) {
      setCreating(false);
    }
  };

  const section = sectionData && sectionData.na ? sectionData.na : "";

  const [row, changeRow] = useState("");
  const [highSeat, changeHighSeat] = useState(0);
  const [lowSeat, changeLowSeat] = useState(0);

  const [splitType, changeSplitType] = useState("");
  const [stockType, changeStockType] = useState("ELECTRONIC");
  const [internalNote, changeInternalNote] = useState("");
  const [publicNote, changePublicNote] = useState("");
  const [hideSeatNumbers, changeHideSeatNumbers] = useState(true);

  const [creating, setCreating] = useState(false);
  const validSplitType = splitType === "" || /^\d*(,\d+)*$/.test(splitType);

  const quantity =
    highSeat === 0 && lowSeat === 0 ? null : highSeat - lowSeat + 1;

  const invalidButton =
    !section || !row || !quantity || creating || !validSplitType;

  return (
    <div
      style={{ marginTop: 10, border: "none" }}
      className="kt-portlet kt-portlet--tab"
    >
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Add Listing on Skybox</h3>
        </div>

        <div className="kt-portlet__head-toolbar">
          <button
            className="btn btn-google"
            data-toggle="tab"
            href="#"
            role="tab"
            onClick={switchToPricingView}
          >
            Back
          </button>
        </div>
      </div>
      <div style={{ paddingBottom: 0 }} className="kt-portlet__body">
        <div style={{ marginBottom: 0 }} className="kt-section">
          <form className="kt-form" onSubmit={onSubmit}>
            <div style={{ flexDirection: "column" }}>
              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Section</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"100%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={"Section"}
                    name={"section"}
                    disabled={true}
                    value={section}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Quantity</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"100%"}
                    type="number"
                    min={0}
                    max={100}
                    className="form-control form-control-sm"
                    placeholder={"Quantity"}
                    name={"quantity"}
                    value={quantity || ""}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Row</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"100%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={"Row"}
                    name={"row"}
                    value={row || ""}
                    onChange={(evt) => {
                      changeRow(evt.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Seats</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"50%"}
                    type="number"
                    min={0}
                    className="form-control form-control-sm"
                    placeholder={"Min"}
                    name={"lowSeat"}
                    value={lowSeat || ""}
                    onChange={(evt) => {
                      const low = parseInt(evt.target.value);
                      changeLowSeat(low);
                      if (low > highSeat) {
                        changeHighSeat(low + 1);
                      }
                    }}
                  />

                  <input
                    width={"50%"}
                    type="number"
                    min={0}
                    className="form-control form-control-sm"
                    placeholder={"Max"}
                    name={"highSeat"}
                    value={highSeat || ""}
                    onChange={(evt) => {
                      const high = parseInt(evt.target.value);
                      changeHighSeat(high);
                      if (high < lowSeat) {
                        changeLowSeat(Math.max(0, high - 1));
                      }
                    }}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Stock Type</label>

                <div className="input-group input-group-sm col-lg-9">
                  <select
                    onChange={(evt) => changeStockType(evt.target.value)}
                    name="stockType"
                    className="form-control"
                    value={stockType}
                  >
                    <option value="ELECTRONIC">Electronic</option>
                    <option value="MOBILE_SCREENCAP">Mobile</option>
                    <option value="HARD">Hard</option>
                    <option value="MOBILE_TRANSFER">Mobile Transfer</option>
                    <option value="FLASH">Flash</option>
                    <option value="PAPERLESS">Paperless</option>
                    <option value="PAPERLESS_CARD">Paperless Card</option>
                  </select>
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Split Type</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"100%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={"Default"}
                    name={"splitType"}
                    value={splitType || ""}
                    onChange={(evt) => {
                      changeSplitType(evt.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Hide Seats</label>

                <div className="input-group input-group-sm col-lg-9">
                  <select
                    onChange={(evt) => changeHideSeatNumbers(Boolean(evt.target.value === "true"))}
                    name="hideSeatNumbers"
                    className="form-control"
                    value={hideSeatNumbers}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Int. Note</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"100%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={""}
                    name={"internalNote"}
                    value={internalNote || ""}
                    onChange={(evt) => {
                      changeInternalNote(evt.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group input-group-sm row">
                <label className={"col-lg-3 col-form-label"}>Public Note</label>
                <div className="input-group input-group-sm col-lg-9">
                  <input
                    width={"100%"}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={""}
                    name={"publicNote"}
                    value={publicNote || ""}
                    onChange={(evt) => {
                      changePublicNote(evt.target.value);
                    }}
                  />
                </div>
              </div>

              <div style={{ marginTop: 10 }} className={"row"}>
                <div
                  style={{ marginBottom: 10, alignSelf: "center" }}
                  className={"col-lg-2"}
                >
                  <button
                    className={"btn btn-success"}
                    disabled={invalidButton ? "disabled" : false}
                    type={"button"}
                    onClick={onSubmit}
                  >
                    {creating ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
