import React from "react";

export default function IncrementTypeButton({
  increment,
  setIncrement,
  pricingKey
}) {
  return (
    <button
      style={{ height: 32, width: 34 }}
      className="btn btn-primary"
      type="button"
      onClick={() =>
        setIncrement({ key: pricingKey, value: increment === 0 ? 1 : 0 })
      }
    >
      {increment === 0 ? "$" : "%"}
    </button>
  );
}
