import React from "react";
import Numeral from "numeral";
export const IncrementTypeIcon = ({ value, amount }) => {
  if (value === 0) {
    return (
      <span
        style={{
          color: amount < 0 ? "#ef210e" : amount > 0 ? "#1dc9b7" : "inherit"
        }}
      >
        {Numeral(amount).format("$0,0.00")}
      </span>
    );
  }
  if (value === 1) {
    return (
      <span
        style={{
          color: amount < 0 ? "#ef210e" : amount > 0 ? "#1dc9b7" : "inherit"
        }}
      >
        {Numeral(amount).format("0.00")}%
      </span>
    );
  }
  return <span>ERROR</span>;
};
