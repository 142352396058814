import React, { useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { SECTION_VIEW, ZONE_VIEW } from "../../Store/EventStore";
import { SALES_TABS } from "../../Store/LayoutStore";

let tooltipData;

function MapSection({ sectionId, setTooltip, selectedListingSections }) {
  const section = useStoreState(state => state.events.getSection(sectionId));
  const sectionsFromZone = useStoreState(state =>
    state.events.getSectionFromZone(section ? section.zi : null)
  );

  const changeTab = useStoreActions(actions => actions.layout.changeTab);

  const mapView = useStoreState(state => state.events.mapView);
  const relatedListings = useStoreState(state => state.stubhub_listings.items);
  const toggleMapSection = useStoreActions(
    actions => actions.pricing.toggleMapSection
  );

  const toggleMapZone = useStoreActions(
    actions => actions.pricing.toggleMapZone
  );

  const salesData = useStoreState(state => state.sales.bySection[sectionId]);

  const toggleMapFilter = useStoreActions(
    state => state.events.toggleMapFilter
  );

  const selectZone = useStoreActions(actions => actions.events.selectZone);
  const unselectZone = useStoreActions(actions => actions.events.unselectZone);

  let className = "";

  const isIncluded = selectedListingSections.includes(section?.name);

  if (isIncluded) {
    className = "onPathSectionSelected";
  } else {
    className = "onPathSectionUnSelected";
  }

  const [noClass, setToNoClass] = useState(false);

  if(!section) {
    return <path
        key="unnamed_section"
        d={section.shapes[0].path}
        //fill={"blue"}
      />
  }

  return (
    <path
      className={!noClass ? className : ""}
      key={sectionId}
      stroke="#ffffff"
      d={section.shapes[0].path}
      zid={sectionId}
      opacity={1}
      strokeWidth="8"
      onClick={evt => {

        const names = section.name.split(" ");
        evt.stopPropagation();
        //changeTab(SALES_TABS);
        if (evt.ctrlKey || evt.metaKey) {
          toggleMapFilter(names[names.length - 1].toLowerCase());
          return;
        }

        if (mapView === ZONE_VIEW) {
          toggleMapZone(sectionsFromZone);
        }
        if (mapView === SECTION_VIEW) {
          console.log("TOGGLING",section.name)
          toggleMapSection(section.name);
        }
      }}
      onMouseEnter={e => {
        const listings = relatedListings.filter(section => {
          if (!section.sectionId) {
            return false;
          }
          return section.sectionId.toString() === sectionId.toString();
        });
        tooltipData = { name: section.name, listings, salesData };

        if (mapView === ZONE_VIEW) {
          selectZone({
            zoneId: section.zi,
            sections: selectedListingSections
          });
        }

        if (mapView === SECTION_VIEW && isIncluded) {
          //setToNoClass(true);
        }
      }}
      onMouseLeave={() => {
        if (mapView === ZONE_VIEW) {
          unselectZone();
        }

        if (mapView === SECTION_VIEW) {
          //setToNoClass(false);
        }
      }}
      onMouseOver={() => {
        if (!section.isSection) {
          return;
        }
        setTooltip(tooltipData);
      }}
      onMouseOut={() => {
        setTooltip("");
      }}
    />
  );
}

export default React.memo(MapSection);
