import React from "react";

export default function ColumnRow({
  name,
  value,
  sortFunc,
  sortField,
  sortOrder,
  filterColumn,
  style
}) {
  return (
    <th
      style={{ whiteSpace: "nowrap", userSelect: "none" }}
      className={`kt-datatable__cell kt-datatable__cell--sort ${
        sortField === value ? "kt-datatable__cell--sorted" : ""
      }`}
    >
      <div style={style} className="filterColumn">
        <span onClick={() => sortFunc(value)} style={{ cursor: "pointer" }}>
          {name}{" "}
          {sortField === value ? (
            sortOrder === "DESC" ? (
              <i
                style={{ right: 0, fontSize: 12 }}
                className={"fa fa-arrow-up"}
              />
            ) : (
              <i
                style={{ right: 0, fontSize: 12 }}
                className={"fa fa-arrow-down"}
              />
            )
          ) : (
            ""
          )}
        </span>
        {filterColumn}
      </div>
    </th>
  );
}
