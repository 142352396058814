import React from "react";

export const WarningSplitIcon = ({ pricing, quantity }) => {
  if (!pricing) {
    return <>N/A</>;
  }

  if (pricing && pricing.split.length <= 0) {
    return <>All</>;
  }

  const warning = [];

  if (Math.max(...pricing.split.map(v => parseInt(v))) > quantity) {
    warning.push(
      <i
        style={{ fontSize: 14, color: "rgb(251, 6, 6)" }}
        className="fa fa-warning"
      />
    );
  }

  return (
    <>
      {warning.length > 0 ? warning[0] : ""}
      {pricing.split.sort((a,b)=> a>b ? 1 : -1).toString()}
    </>
  );
};
