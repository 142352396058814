import React from "react";
import { useMousePosition } from "../../utils/mouse_move";

function MapTooltip({ tooltip }) {
  const position = useMousePosition();
  if (!tooltip) {
    return null;
  }

  const numOfSales = tooltip.salesData ? tooltip.salesData.numOfListings : 0;
  const numOfTickets = tooltip.salesData ? tooltip.salesData.numOfTickets : 0;

  return (
    <div
      className="map-popover-tooltip mapPopover"
      exchange-map-tooltip=""
      style={{
        visibility: "visible",
        fontSize: 12,
        opacity: 1,
        top: position.y - 90,
        left: position.x
      }}
    >
      <div className="mapPopover__arrow"></div>
      <div className="mapPopover__content table table__pricingOverview pricingTable__one">
        <div className="mapPopover__line mapPopover__line--header">
          {tooltip.name}
        </div>
        <div className="mapPopover__line">
          <div className="mapPopover__cell mapPopover__cell--price">
            <span style={{ fontSize: 12 }}>{numOfSales} ({numOfTickets})</span>
          </div>
          <div className="mapPopover__cell mapPopover__cell--seats">
            <span style={{ fontSize: 12 }}>
              {tooltip.listings.length} (
              {tooltip.listings.reduce((acc, curr) => acc + curr.quantity, 0)})
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapTooltip;
