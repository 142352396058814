import React, { useState } from "react";
import { useStoreActions } from "easy-peasy";

export default function AddEventNote({ eventId }) {
  const [note, changeNote] = useState("");
  const addNote = useStoreActions(actions => actions.events.addNoteThunk);

  return (
    <>
      <div className="form-group form-group-last">
        <textarea
          className="form-control"
          rows="3"
          value={note}
          onChange={evt => changeNote(evt.target.value)}
        ></textarea>
      </div>
      <button
        onClick={() => {
          addNote({
            event_id: eventId,
            note
          });
          changeNote("");
        }}
        style={{ marginTop: 10 }}
        disabled={!note ? "disabled" : false}
        className={`btn btn-success`}
      >
        Submit
      </button>
    </>
  );
}
