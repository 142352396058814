import React from "react";
import { useStoreState } from "easy-peasy";
import AddEventNote from "./AddEventNote";
import { Note } from "./Note";
import {
    NOTE_FULL_HEIGHT,
    NOTE_FULL_VIEW,
    NOTE_SMALL_HEIGHT
} from "../../Store/LayoutStore";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment/moment";

export default function GetInsList() {
    const getIns = useStoreState(state => state.events.getIns);
    const noteView = useStoreState(state => state.layout.noteView);

    if (!getIns || getIns.length === 0) return null;

    const getSnapshotDateLabels = (data) => {
        const sortedData = data.sort((a, b) => moment(a.created).diff(moment(b.created)));
        return sortedData.map(item => {
                const date = moment.utc(item.created);
                return {
                    date: date.format('DD MMM'),
                    get_in: item.getin
                };
            }
        )
    }

    const graphData = getSnapshotDateLabels(getIns);

    const yAxisPadding = 5;

    const maxDataValue = Math.max(...graphData.map(item => item.getin));

    const yAxisMax = maxDataValue + yAxisPadding;

    return (
        <div
            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
            style={{ height: `calc(100vh - 695px + ${noteView !== NOTE_FULL_VIEW ? NOTE_FULL_HEIGHT - NOTE_SMALL_HEIGHT : 0}px)`, overflow: "auto" }}
        >
            {graphData && (
                <LineChart
                    width={435}
                    height={260}
                    data={graphData}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip
                        formatter={(value) => new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(value)}
                    />
                    <Legend />
                    <Line type="monotone" dataKey="get_in" stroke="#8884d8" dot={false} />
                </LineChart>
            )}

        </div>
    );
}
