import { SalesStore } from "../types";
import { action, thunk, thunkOn } from "easy-peasy";

export const SaleStore : SalesStore = {
  rows: [],
  bySection: {},
  error: null,
  numFound: 0,
  isLoading: true,

  onLoadEvent: thunkOn(
    (actions, store) => store.events.loadEventDataThunk.startType,
    async (actions, target) => {
      console.log(target.payload);
      actions.unselectSales();
      // @ts-ignore
      actions.loadSalesThunk({ eventId: target.payload.seatgeek_id });
    }
  ),
  loadSalesThunk: thunk(async (actions, payload) => {
    try {
      const response = await fetch(
        `https://bowman.plessinc.com/api/seatgeek/sales/${payload.eventId}`
      );
      const jsonData: any = await response.json();
      actions.loadSales(jsonData);
    } catch (err) {
      console.log(err);
    }
  }),
  loadSales: action((state, payload) => {
    state.rows = payload;
    state.numFound = payload.length;
    state.isLoading = false;

    const salesBySection: any = {};

    payload.forEach((sale: any) => {
      if (!salesBySection[sale.s]) {
        salesBySection[sale.s] = {
          numOfListings: 1,
          numOfTickets: sale.q
        };
      }
      salesBySection[sale.s].numOfListings += 1;
      salesBySection[sale.s].numOfTickets += sale.q;
    });
    state.bySection = salesBySection;
  }),
  unselectSales: action(state => {
    state.rows = [];
    state.numFound = 0;
    state.isLoading = true;
    state.bySection = {};
  })
}