
export const getHighlightColor = pricing => {
  let highlightColor = "inherit";

  if (pricing) {
    if (parseFloat(pricing.floor) === parseFloat(pricing.cmp)) {
      highlightColor = "rgb(255, 0, 0, 0.28)";
    } else if (
      pricing.ceiling > 0 &&
      parseFloat(pricing.ceiling) === parseFloat(pricing.cmp)
    ) {
      highlightColor = "rgba(34, 185, 255, 0.5)";
    } else if (!pricing.cmp || pricing.cmp <= 0) {
      highlightColor = "#e2e5ec";
    } else {
      highlightColor = "rgba(255, 184, 34, 0.35)";
    }

    return highlightColor;
  }
};

export const getSeats = seatsString => {
  const seatsArray = seatsString.split(",");
  const seats = [];
  seats[0] = seatsArray[0];
  if (seatsArray.length > 1) {
    seats[1] = seatsArray[seatsArray.length - 1];
  }
  return seats;
};
