import {INITIAL_VALUE, ReactSVGPanZoom, TOOL_AUTO, UncontrolledReactSVGPanZoom} from "react-svg-pan-zoom";
import React, {useEffect, useRef, useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import MapSection from "./MapSection";
import { GeneralAdmissionMap } from "./GeneralAdmissionMap";
import { ResetSectionButton } from "./ResetSectionButton";
import { SectionZoneButtons } from "./SectionZoneButtons";
import { NOTE_FULL_HEIGHT, NOTE_FULL_VIEW, NOTE_SMALL_HEIGHT } from "../../Store/LayoutStore";
import { MatchEvent } from "./MatchEvent";

const Map = React.memo(props => {
  const selectedEvent = useStoreState(state => state.events.selectedEvent);
  const allSections = useStoreState(state => state.events.mapSections);
  const mapUrl = useStoreState(state => state.events.mapUrl);
  const selectedListingSections = useStoreState(
    state => state.pricing.selectedListingSections
  );

  const Viewer = useRef(null);
  const isLoadingEvent = useStoreState(state => state.events.loading);

  const unmatchEvent = useStoreActions(
    actions => actions.events.unmatchEventThunk
  );

  useEffect(() => {
    if (allSections) {
      Viewer?.current?.fitToViewer();
    }
  }, [Viewer, allSections]);

  if (isLoadingEvent) {
    return (
        <div
            style={{
              marginTop: 10,
              height: NOTE_SMALL_HEIGHT
            }}
            className="kt-portlet"
        >
          <div
              style={{
                margin: "auto",
                fontSize: 28,
                fontWeight: 400
              }}
              className="kt-portlet__body"
          >
            <div className="kt-spinner kt-spinner--md kt-spinner--info"></div>
          </div>
        </div>
    );
  }

  if (selectedEvent.seatgeek_id == 0) {
    return <MatchEvent/>
  }

  if (
    !allSections
  ) {
    return <GeneralAdmissionMap />;
  }

  return (
    <div

      className="mapSection__holder"
      id="pricing-overview-exchange-map"
    >
      <div>
        <button
          onClick={() => {
            unmatchEvent(selectedEvent);
          }}
          style={{marginTop: 10}}
          className={"btn btn-outline-danger"}
        >
          <i className="fa fa-chain-broken" aria-hidden="true"></i>
          Unmatch SG
        </button>
      </div>
      <div className="mapholder__height">
        <div className="map__holder">
          <div className="exchangeMap ng-scope">
            <div className="mapCall" id="svg-wrapper">
              <div className="exchangeMap__svg" id="stubhub-svg">
                <div style={{width: 1024, height: 768}} className="mapcontainer">
                  <div
                    className="svgcontainer"
                  >
                    {/* <SectionZoneButtons
                      style={{
                        position: "absolute",
                        zIndex: 999,
                        marginLeft: 20,
                        marginTop: 20
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        zIndex: 999,
                        marginLeft: 250,
                        marginTop: 25,
                        fontSize: 18
                      }}
                    >
                      {`${numberOfListings} (${numberOfTickets})`}
                    </div>

                    <ResetSectionButton
                      style={{
                        position: "absolute",
                        zIndex: 999,
                        marginLeft: 470,
                        marginTop: 20
                      }}
                    />*/}
                    <UncontrolledReactSVGPanZoom
                      background={"#ffffff"}
                      ref={Viewer}
                      tool={TOOL_AUTO}
                      onChangeTool={() => {
                      }}
                      height={768}
                      width={1024}
                      //onChangeValue={setValue}
                      //value={value}
                      //SVGStyle={{fill:'#ffffff', "abc": "121212"}}
                      detectAutoPan={false}
                      customToolbar={() => <div>...</div>}
                    >

                      <svg
                        height={768}
                        version="1.1"
                        width={1024}
                        viewBox="0 0 10240 7680"
                        fill={"ffffff"}
                        //xmlns="http://www.w3.org/2000/svg"
                        //xmlnsXlink="http://www.w3.org/1999/xlink"
                        //preserveAspectRatio="xMinYMin"
                      >

                        <image width={10240} height={7680}
                               xlinkHref={`https://mapsapi.tmol.io/maps/geometry/image/${mapUrl}`}></image>

                        <g className="polygons">
                          {allSections.map(section => {
                            return (
                              <MapSection
                                key={section.id}
                                sectionId={section.id}
                                setTooltip={props.setTooltip}
                                selectedListingSections={selectedListingSections}
                              />
                            );
                          })}
                        </g>

                        <g className={"labels"}>

                          {allSections.map(section => {

                            if (!section.shapes || section.shapes.length <= 0 || !section.shapes[0].labels || section.shapes[0].labels.length <= 0) {
                              return null
                            }

                            const x = section.shapes[0].labels[0].x;
                            const y = section.shapes[0].labels[0].y
                            let i = 1;

                            const splitted = section.shapes[0].labels[0].text.split('\r')

                            return (<text
                              style={{
                                fontWeight: 700,
                                fontFamily: "Averta",
                                userSelect: "none"
                              }}
                              pointerEvents={"none"}
                              fill={"#ffffff"}
                              fontSize={section.shapes[0].labels[0].size || 200}
                              transform={`rotate(${section.shapes[0].labels[0].angle * -1},${x},${y})`}
                              key={`text-label-${section.id}-${section.name}`}>

                              {splitted.map(text => {
                                return <tspan style={{textAnchor: "middle"}} x={x} y={y}
                                              dy={`${i++}em`}>{text}</tspan>
                              })}


                            </text>)


                          })}

                        </g>

                      </svg>

                    </UncontrolledReactSVGPanZoom>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Map;
