import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { DeleteCriteriaButton } from "./Buttons/DeleteCriteriaButton";

export default function ListingGroupRow({ group }) {
  const groupId = group.id || 0;

  const isGroupChecked = useStoreState(state =>
    state.listings.isGroupChecked(groupId)
  );
  const uncheckGroup = useStoreActions(
    actions => actions.listings.uncheckGroup
  );
  const checkGroup = useStoreActions(actions => actions.listings.checkGroup);
  const deleteGroup = useStoreActions(
    actions => actions.groups.deleteGroupThunk
  );

  return (
    <tr>
      <td
        style={{
          fontSize: "1.2rem",
          fontWeight: 500,
          color: "#fff",
          paddingLeft: 5,
          backgroundColor: "#505558",
          height: 40
        }}
        colSpan={25}
      >
        <label
          onClick={evt => evt.stopPropagation()}
          className="kt-checkbox kt-checkbox--single kt-checkbox--solid"
        >
          <input
            type="checkbox"
            checked={isGroupChecked}
            onChange={e => {
              if (!isGroupChecked) {
                checkGroup(groupId);
                return;
              }
              uncheckGroup(groupId);
            }}
          />
          &nbsp;
          <span></span>
        </label>
        {group.name}

        {!!groupId && (
          <DeleteCriteriaButton
            onAction={() =>
              deleteGroup({ groupId: group.id, pricingId: group.pricing_id })
            }
            key={group.id}
            style={{ marginLeft: 15 }}
          />
        )}
      </td>
    </tr>
  );
}
