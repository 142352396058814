import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import { useStoreState, useStoreActions } from "easy-peasy";

function Layout(props) {
  const isSidebarOpen = useStoreState(state => state.layout.sidebarOpen);
  const openSidebar = useStoreActions(state => state.layout.openSidebar);
  const isCriteriaSaved = useStoreState(state => state.pricing.isCriteriaSaved);
  const clearSavedCriteria = useStoreActions(state => state.pricing.clearSavedCriteria);

  const ctrlFFunction = e => {
    if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
      e.preventDefault();
      openSidebar();
      document.getElementById("artistSearch").select();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", ctrlFFunction);
    return () => {
      window.removeEventListener("keydown", ctrlFFunction);
    };
  });

  return (
    <div
      onClick={() => {
        if (isCriteriaSaved) {
          clearSavedCriteria();
        }
      }}
      className="kt-grid kt-grid--hor kt-grid--root"
    >
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <Sidebar isOpen={isSidebarOpen} />
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
