import React from "react";
import { useStoreState } from "easy-peasy";
import ListingGroupRow from "./ListingGroupRow";
import ListingRow from "./ListingRow";
import { LISTING_SIMPLE_VIEW } from "../../Store/LayoutStore";

const addEmptyRow = i => (
  <tr key={`empty-row-${i}`}>
    <td
      style={{ height: 40, fontSize: 16, textAlign: "center", fontWeight: 500 }}
      colSpan={24}
    >
      <span>No listings</span>
    </td>
  </tr>
);

export default function ListingsGrouped({ listings, onTabPress }) {
  const groups = useStoreState(state => state.groups.items);
  const groups_listings = useStoreState(state => state.groups.listings);

  const filteredSections = useStoreState(
    state => state.events.selectedSections
  );
  const filters = useStoreState(state => state.listings.filters);

  const hasFilters = filters.length > 0 || filteredSections.length > 0;

  const components = [];

  let usedListings = [];

  const simpleDetailedView = useStoreState(
    state => state.layout.listingSimpleDetailedView
  );
  const isSimpleView = simpleDetailedView === LISTING_SIMPLE_VIEW;

  let i = 0;

  Object.keys(groups).forEach(key => {
    const filterListingInGroup = listings.filter(listing =>
      groups_listings[key].map(v => v.listing_id).includes(listing.id)
    );

    if ((!isSimpleView && !hasFilters) || filterListingInGroup.length > 0) {
      components.push(
        <ListingGroupRow key={`group-${key}`} group={groups[key]} />
      );
    }

    if (!isSimpleView && !hasFilters && filterListingInGroup.length <= 0) {
      components.push(addEmptyRow(i));
      i += 1;
    }

    filterListingInGroup.forEach((listing, idx) => {
      if (idx === 0 || !isSimpleView) {
        components.push(
          <ListingRow
            onTabPress={onTabPress}
            groups={groups}
            key={listing.id}
            id={listing.id}
          />
        );
      }
    });

    usedListings = [...usedListings, ...filterListingInGroup];
  });

  const ungroupedListings = listings.filter(listing => {
    return !usedListings.some(used => used.id === listing.id);
  });

  if (ungroupedListings.length > 0) {
    components.push(
      <ListingGroupRow key={"ungrouped"} group={{ name: "Ungrouped" }} />
    );
    ungroupedListings.forEach((listing) => {
      components.push(
        <ListingRow
          onTabPress={onTabPress}
          groups={groups}
          key={listing.id}
          id={listing.id}
        />
      );
    });
  }

  return (
    <>
      {components.map(component => {
        return component;
      })}
    </>
  );
}
