import { action, thunk } from "easy-peasy";
import LogRocket from "logrocket";
import { AuthModel } from "../types";

export const AuthStore: AuthModel = {
  authenticateThunk: thunk(async (actions, payload) => {
    if (process.env.NODE_ENV !== "production") {
      actions.authenticate({
        id: 9999,
        name: "Local User",
        email: "local@local.com",
        picture:
          "https://lh4.googleusercontent.com/-ayi-fktu544/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rf5MgAifl1g5mCUSBYpDGcuKXnogQ/photo.jpg"
      });
      return;
    }
    const response = await fetch(`${process.env.REACT_APP_DOMAIN}/auth/user`);
    const { user } = await response.json();
    if (!user) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}/auth/google`;
      return;
    }
    LogRocket.identify(user.sub, {
      name: user.name,
      email: user.email
    });
    actions.authenticate(user);
  }),
  authenticate: action((state, payload) => {
    state.user = payload;
  }),
  user: null
};
