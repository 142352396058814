import React from "react";
import { useStoreState } from "easy-peasy";
import OwnSalesRow from "./OwnSaleRow";

function OwnSales() {
    const eventId = useStoreState(state => state.events.eventId);
    const ownSales = useStoreState(state => state.serena.ownSales);

  if (!eventId) {
    return null;
  }

  console.log(ownSales)

  return (
        <table
          className="kt-datatable__table"
          width="100%"
          style={{ display: "table" }}
        >
          <thead
            style={{ display: "table-header-group"}}
            className="kt-datatable__head"
          >
            <tr className="kt-datatable__row">
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Date</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Quantity</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Section</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Row</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Margin(%)</span>
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                <span>Total</span>
              </th>
            </tr>
          </thead>
          <tbody
            style={{ display: "table-row-group" }}
            className="kt-datatable__body"
          >
            {ownSales.map(sale => (
              <OwnSalesRow key={`${eventId}-${sale.invoiceDate}`} sale={sale} />
            ))}
          </tbody>
        </table>
  );
}

export default OwnSales;
