import { StubhubListingStore } from "../types";
import {action, computed, thunk, thunkOn } from "easy-peasy";
const { filterListings, buildDictionary } = require("@jfortin/kobe-criteria");

export const StubhubListing: StubhubListingStore = {
  isLoading: false,
  items: [],
  setToLoading: action(state => {
    state.isLoading = true;
  }),
  numberOfListingsFiltered: computed([state => state.filteredStubhubListings], items => {
    return items.filter((l:any) => !l.sellerOwnInd).length;
  }),
  numberOfTicketsFiltered: computed([state => state.filteredStubhubListings], items => {
    return items.filter((l:any) => !l.sellerOwnInd).reduce((acc : any,curr : any) => {
      return acc + curr.quantity;
    }, 0)
  } ),
  numberOfListings: computed([state => state.items], items => {
    return items.length;
  }),
  numberOfTickets: computed([state => state.items], items => {
    return items.reduce((acc : any,curr : any) => {
      return acc + curr.quantity;
    }, 0)
  } ),
  allSgSections: computed([state => state.items], items => {
    const uniqueSections : Set<string> = new Set();
    items.forEach(item => {
      if (item.sectionName) {
        uniqueSections.add(item.sectionName.toLowerCase());
      }
    })
    return Array.from(uniqueSections);
  }),
  filteredStubhubListings: computed(
    [
      (_, store) => {
        return store.listings.selectedListing;
      },
      (_, store) => {
        return store.events.isGA;
      },
      state => state.items,
      (_, store) => store.pricing.selectedPricing,
      (_, store) => {
          return store.events.mapSections
      },
      (_, store) => {
          return store.events.sectionMapping
      }
    ],
    (selectedListing, isGA, stubhubListings, selectedPricing, ticketmasterSections, mappingDic) => {
      if (!selectedPricing) {
        return stubhubListings;
      }

      if (!ticketmasterSections) {
        ticketmasterSections = [];
      }

      const dic = buildDictionary(ticketmasterSections.map((s:any) => s.name), stubhubListings.map(listing => listing.sectionName),mappingDic);

      const newFilteredSH :any = filterListings(
        selectedPricing,
        stubhubListings,
        dic,
        isGA
      );

      return newFilteredSH;
    }
  ),
  onLoadEvent: thunkOn(
    (actions, store) => store.events.loadEventDataThunk.startType,
    async (actions, target) => {
      // @ts-ignore
      actions.loadStubhubListingsThunk({ eventId: target.payload.seatgeek_id });
    }
  ),

  loadStubhubListingsThunk: thunk(async (actions, payload) => {
    if (!payload.reload) {
      actions.setToLoading();
    }

    console.log(payload.eventId);

    const response = await fetch(
      `/api/listings/related_listings/${payload.eventId}`
    );
    const jsonData = await response.json();

    if (jsonData.length > 0) {
      const lowestPrice = jsonData.reduce((prev: any, current: any) => (prev.pricePerProduct.amount < current.pricePerProduct.amount) ? prev : current);
      actions.loadLowestPrice(lowestPrice.pricePerProduct.amount);
    }

    actions.loadStubhubListings(jsonData);
  }),
  loadStubhubListings: action((state, payload) => {
    state.items = payload;
    state.isLoading = false;
  }),

  lowestPrice: null,
  loadLowestPrice: action((state, payload) => {
    state.lowestPrice = payload;
  }),
};
